import { MutableRefObject, useState } from 'react';
import Button from '../components/fields/Button';
import { Editor as TinyMCEEditor } from 'tinymce';

interface CopyToClipboardProps {
  editorRef: MutableRefObject<TinyMCEEditor>;
  title: string;
  extra?: string;
  mobile?: boolean;
}

const CopyToClipboard = ({
  editorRef,
  title,
  extra,
  mobile,
}: CopyToClipboardProps) => {
  const [copyToClipboardStatus, setCopyToClipboardStatus] = useState<
    'default' | 'complete' | 'error'
  >('default');

  const handleCopy = () => {
    try {
      const content = editorRef.current?.getContent() || '';
      navigator.clipboard.write([
        new ClipboardItem({
          'text/html': new Blob(['<h1>' + title + '</h1>\n\n' + content], {
            type: 'text/html',
          }),
        }),
      ]);
      setCopyToClipboardStatus('complete');
    } catch (e) {
      setCopyToClipboardStatus('error');
    }
  };

  return (
    <>
      {mobile ? (
        // Mobile-specific rendering
        <Button
          type={copyToClipboardStatus === 'error' ? 'red' : 'blue'}
          label="Copy to Clipboard"
          leftMargin
          className={extra}
          customButton="px-2 rounded-lg py-1 mb-2  inline-block  sm:w-auto text-sm sm:text-base sm:leading-normal truncate"
          loading={undefined}
          completed={copyToClipboardStatus === 'complete'}
          onClick={handleCopy}
        />
      ) : (
        // Desktop-specific rendering
        <Button
          type={copyToClipboardStatus === 'error' ? 'red' : 'blue'}
          label="Copy to Clipboard"
          leftMargin
          className={extra}
          customButton="rounded-lg mb-2 px-4 py-1"
          loading={undefined}
          completed={copyToClipboardStatus === 'complete'}
          onClick={handleCopy}
        />
      )}
    </>
  );
};

export default CopyToClipboard;
