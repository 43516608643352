import { PagedCollection } from '../../interfaces/Collection';
import Moment from 'react-moment';
import { PageCard } from 'components/card/PageCard';
import DataTableView from 'components/datatable/DataTableView';
import useCollectionUrl from 'apiplatform/hooks/collectionurl';
import { Link, useNavigate } from 'react-router-dom';
import { ContentTheme } from 'apiplatform/interfaces/ContentTheme';
import useRetrieve from 'hooks/useRetrieve';
import { Business } from 'apiplatform/interfaces/Business';
import { ExplainerText } from '../base/ExplainerText';
import { MdAdd, MdDelete, MdEdit } from 'react-icons/md';
import IconButton from 'components/fields/IconButton';
import TooltipHorizon from 'components/tooltip';
import Create from './Create';
import EmptyModalBox from '../base/modal/EmptyModalBox';
import Delete from './Delete';
import { IoMdEye } from 'react-icons/io';
import { FaWordpressSimple } from 'react-icons/fa';
import ShowWordpress from '../businesswordpressconfig/Show';
import Show from './Show';
import { useAuthContext } from 'components/auth/AuthProvider';
import { useEffect, useState } from 'react';
import { IoMenu } from 'react-icons/io5';
import Button from '../../../components/fields/Button';
import MobileOptions from './MobileOptions';
const List = () => {
  const { url } = useCollectionUrl('/businesses');
  const { retrieved, loading } = useRetrieve<PagedCollection<Business>>(url);

  const auth = useAuthContext();
  const navigate = useNavigate();
  const [menuVisible, setMenuVisible] = useState(false);
  const [activeRow, setActiveRow] = useState<Business | null>(null);

  const handleToggleMenu = (row: Business) => {
    setActiveRow(row);
    setMenuVisible(!menuVisible);
  };

  const closeMenu = () => {
    setMenuVisible(false);
    setActiveRow(null);
  };
  useEffect((): any => {
    if (auth.isSingleNotOwnerBusiness() && loading === false) {
      const business = retrieved['hydra:member'][0];
      const url = `/business/${business.id}/theme/${
        (business.defaultContentTheme as ContentTheme).id
      }/queue?allThemes=1`;

      return navigate(url);
    }
  }, [auth, retrieved, loading, navigate]);
  return (
    <>
      <ExplainerText
        text="This page serves as a directory of your businesses and locations.
          Simply click on a business name to generate content specifically
          tailored for that particular business."
      />
      <PageCard>
        <DataTableView
          retrieved={retrieved}
          loading={loading}
          noDataComponent={
            <>
              You don't have any businesses yet.
              <br />
              Click the [+] plus sign to create one.
            </>
          }
          columns={[
            {
              name: 'Business Name',
              id: 'name',
              sortable: true,
              cell: (row) => (
                <Link
                  to={`/business/${row.id}/theme/${
                    (row.defaultContentTheme as ContentTheme).id
                  }/queue?allThemes=1`}
                  className="w-full py-4 text-sm font-bold text-navy-700 dark:text-white"
                >
                  {row.name}
                </Link>
              ),
            },
            {
              name: 'Created at',
              id: 'createdAt',
              sortable: true,
              hide: 576,
              width: '160px',
              cell: (row) => (
                <Moment
                  format="MMMM Do YYYY"
                  className="text-navy-700 dark:text-white "
                >
                  {row.createdAt}
                </Moment>
              ),
            },
            {
              name: (
                <div className="hidden w-full text-right sm:block">
                  {auth.isOwner() && (
                    <EmptyModalBox
                      width={800}
                      item={
                        <TooltipHorizon content="Add Business">
                          <IconButton>
                            <MdAdd />
                          </IconButton>
                        </TooltipHorizon>
                      }
                    >
                      <Create />
                    </EmptyModalBox>
                  )}
                </div>
              ),
              width: '160',
              cell: (row) => (
                <div className="w-full text-right">
                  <MobileOptions row={row} />

                  <TooltipHorizon content="Create & Access Content">
                    <Link
                      to={`/business/${row.id}/theme/${
                        (row.defaultContentTheme as ContentTheme).id
                      }/queue?allThemes=1`}
                    >
                      <IconButton extra="hidden md:block">
                        <IoMdEye />
                      </IconButton>
                    </Link>
                  </TooltipHorizon>

                  {auth.isBusinessOwner(row.id) && (
                    <EmptyModalBox
                      item={
                        <TooltipHorizon content="CMS Integrations">
                          <IconButton extra="hidden md:block">
                            <FaWordpressSimple />
                          </IconButton>
                        </TooltipHorizon>
                      }
                    >
                      <ShowWordpress business={row} />
                    </EmptyModalBox>
                  )}

                  {auth.isBusinessOwner(row.id) && (
                    <EmptyModalBox
                      width={800}
                      item={
                        <TooltipHorizon content="Edit">
                          <IconButton extra="hidden md:block">
                            <MdEdit />
                          </IconButton>
                        </TooltipHorizon>
                      }
                    >
                      <Show business={row} />
                    </EmptyModalBox>
                  )}

                  {auth.isBusinessOwner(row.id) && (
                    <EmptyModalBox
                      item={
                        <TooltipHorizon content="Delete">
                          <IconButton extra="hidden md:block">
                            <MdDelete />
                          </IconButton>
                        </TooltipHorizon>
                      }
                    >
                      <Delete item={row} />
                    </EmptyModalBox>
                  )}
                </div>
              ),
            },
          ]}
        />
      </PageCard>
    </>
  );
};

export default List;
