/*eslint-disable*/
import React from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <div className="z-[1.5] mx-auto flex w-full max-w-[90%] flex-col items-center justify-between px-0 pb-8 pt-12 xl:w-[1170px] xl:max-w-[1170px] xl:flex-row">
      <p className="mb-4 text-center text-sm text-[#636e91] sm:!mb-0 md:text-base">
        ©{new Date().getFullYear()} {process.env.REACT_APP_BRAND_NAME}. All
        Rights Reserved.
      </p>
      <ul className=" flex flex-nowrap items-center md:flex-wrap">
        <li className="mr-12">
          <a
            target="blank"
            href="mailto:cs@customergain.com"
            className="text-sm text-[#636e91] hover:text-[#636e91] md:text-base"
          >
            Support
          </a>
        </li>
        {/* <li className="mr-12">
          <a
            target="blank"
            href="#"
            className="text-sm text-[#636e91] hover:text-[#636e91] md:text-base"
          >
            License
          </a>
        </li> */}
        <li className="mr-10 md:mr-12">
          <Link
            to="/policies-center"
            className="text-sm text-[#636e91] hover:text-[#636e91] md:text-base "
          >
            <p className="">Privacy & Terms of Use</p>
          </Link>
        </li>
        {/* <li>
          <a
            target="blank"
            href="#"
            className="text-sm text-[#636e91] hover:text-[#636e91] md:text-base"
          >
            Blog
          </a>
        </li> */}
      </ul>
    </div>
  );
}
