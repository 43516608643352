import React from 'react';
import { PageCard } from '../components/card/PageCard';
import CenteredButtons from '../apiplatform/components/base/CenteredButtons';
import Button from '../components/fields/Button';

interface ContentStatusProps {
  setArticleStatus: (newStatus: 'sent' | 'new') => void;
  close?: () => void; // Optional close function
}

const ContentStatus = ({ setArticleStatus, close }: ContentStatusProps) => {
  return (
    <PageCard>
      <p className="text-wrap pb-6 pt-12">
        You can change the status displayed in the Queue for the content below:
      </p>

      <CenteredButtons modal>
        <Button
          type="blue"
          label="Set Status to Published"
          customButton="px-2 rounded-lg py-2 mb-2 inline-block sm:text-wrap sm:w-auto text-sm sm:text-base truncate whitespace-normal sm:whitespace-nowrap"
          onClick={() => {
            setArticleStatus('sent');
            if (close) close();
          }}
        />
        <Button
          type="blue"
          label="Set Status to Un-Published"
          customButton="px-2 rounded-lg py-2 mb-2 inline-block sm:text-wrap sm:w-auto text-sm sm:text-base truncate whitespace-normal sm:whitespace-nowrap"
          leftMargin
          onClick={() => {
            setArticleStatus('new');
            if (close) close();
          }}
        />
      </CenteredButtons>
    </PageCard>
  );
};

export default ContentStatus;
