import ErrorAlert from 'components/alert/ErrorAlert';
import { PageCard } from 'components/card/PageCard';
import Button from 'components/fields/Button';
import Header from '../base/Header';
import useCreate from 'hooks/useCreate';
import { CreateManager } from 'apiplatform/interfaces/Manager';
import Form from './Form';
import { useEffect } from 'react';

interface CreateProps {
  close?: () => void;
}

const Create = ({ close }: CreateProps) => {
  const { created, loading, error, create } =
    useCreate<CreateManager>(`/users/managers`);

  useEffect(() => {
    if (created) {
      close();
    }
  }, [created, close]);

  return (
    <PageCard innerPaddingOff={true}>
      <div className="grid grid-cols-4 grid-rows-1">
        <div className=" col-span-3 row-start-1 px-4">
          <Header text="Add Manager" />
          {error && <ErrorAlert message={error.message} />}
        </div>
        <div className="col-span-4 row-start-2">
          <Form
            onSubmit={create}
            error={error}
            loading={loading}
            label="Create"
            footer={
              <Button
                leftMargin="modal"
                type="blue"
                label="Cancel"
                onClick={close}
              />
            }
          />
        </div>
      </div>
    </PageCard>
  );
};
export default Create;
