import axios from 'axios';
import Card from 'components/card';
import InputField from 'components/fields/InputField';
import Spinner from 'components/spinner/Spinner';
import NoAuthCentered from 'layouts/auth/types/NoAuthCentered';
import { useState } from 'react';
import { Link } from 'react-router-dom';

function ForgotPassword() {
  const [email, setEmail] = useState<string>('');
  const handleEmail = (e: any) => setEmail(e.target.value);
  const [loading, setLoading] = useState<boolean>(false);

  const handleReset = async () => {
    setLoading(true);
    const url = process.env.REACT_APP_API_URL + '/users/forgot-password';

    await axios
      .post(url, { email: email })
      .then((r) => {
        setLoading(false);
        setEmail('');
      })
      .catch((e) => {
        setLoading(false);
        setEmail('');
      });
  };
  return (
    <NoAuthCentered
      maincard={
        <Card
          extra={
            'max-w-[405px] md:max-w-[550px] h-max mt-32 md:mt-[120px] mx-2.5 md:mx-auto mb-auto pb-2.5 pt-8 md:pt-2.5 px-4 md:!p-[50px]'
          }
        >
          <p className="mb-2 text-2xl  font-bold text-navy-700 dark:text-white md:text-4xl ">
            Forgot Your Password?
          </p>
          <p className="mb-8 ml-1 mt-[10px] text-sm text-[#636e91] md:text-base">
            No problem. Just let us know your email address and we'll email you
            a password reset link that will allow you to choose a new one.
          </p>
          {/* Email */}
          <InputField
            variant="auth"
            label="Email"
            placeholder="Your email"
            id="email"
            type="text"
            value={email}
            onChange={handleEmail}
          />
          {loading ? <Spinner /> : ''}
          {/* button */}
          <button
            className="linear mt-4 w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            onClick={handleReset}
          >
            Email password reset link
          </button>

          <div className="mt-3">
            <span className="text-sm font-medium text-navy-700 dark:text-gray-500">
              Already a member?
            </span>
            <Link
              className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-500 dark:text-white"
              to="/"
            >
              Sign In
            </Link>
          </div>
        </Card>
      }
    />
  );
}

export default ForgotPassword;
