// MobileOptions
import React, { useEffect, useRef, useState } from 'react';
import { IoMdEye } from 'react-icons/io';
import { FaWordpressSimple } from 'react-icons/fa';
import { MdEdit, MdDelete, MdAdd } from 'react-icons/md';
import { IoMenu } from 'react-icons/io5';
import { ContentTheme } from 'apiplatform/interfaces/ContentTheme';
import { useNavigate } from 'react-router-dom';
import { Business } from 'apiplatform/interfaces/Business';
import { useAuthContext } from 'components/auth/AuthProvider';
import Button from '../../../components/fields/Button';
import EmptyModalBox from '../base/modal/EmptyModalBox';
import Show from './Show';
import Delete from './Delete';
import ShowWordpress from '../businesswordpressconfig/Show';
import TooltipHorizon from '../../../components/tooltip';
import IconButton from '../../../components/fields/IconButton';
import Create from './Create';

interface MobileOptionsProps {
  row: Business;
}

const MobileOptions: React.FC<MobileOptionsProps> = ({ row }) => {
  const navigate = useNavigate();
  const auth = useAuthContext();
  const [menuVisible, setMenuVisible] = useState(false);


  const handleToggleMenu = () => setMenuVisible(!menuVisible);
  const closeMenu = () => setMenuVisible(false);

 

  return (
    <>
      <Button
        type="blue"
        label={<IoMenu className="text-base" />}
        customButton="rounded-lg px-[8px] py-[8px] w-full w-min text-sm sm:text-base truncate md:hidden"
        onClick={handleToggleMenu}
      />

      {menuVisible && (
        <div
          className={`border-3 fixed bottom-0 left-0 right-0 mx-auto flex w-full flex-col justify-start rounded-t-[20px] border  
            bg-white pb-4 text-left shadow-2xl dark:bg-navy-700 dark:text-white !z-10 md:hidden`}
        >
          <p className="my-2 pl-6 text-gray-500">{row.name}</p>
          <Button
            type="white"
            label={
              <div className="my-2 inline-flex pl-2">
                <IoMdEye className="ml-4 text-lg text-blue-900" />
                <p className="pl-2">Create & Access Content</p>
              </div>
            }
            customButton="mt-3 text-sm text-left text-gray-800 dark:text-white"
            onClick={() => {
              navigate(
                `/business/${row.id}/theme/${
                  (row.defaultContentTheme as ContentTheme).id
                }/queue?allThemes=1`
              );
              closeMenu();
            }}
          />

          <div className="my-2 border-t border-gray-500"></div>

          {auth.isBusinessOwner(row.id) && (
            <>
              <EmptyModalBox
                item={
                  <Button
                    type="white"
                    label={
                      <div className="inline-flex pl-2">
                        <FaWordpressSimple className="ml-4 text-lg text-blue-900" />
                        <p className="pl-2">CMS Integrations</p>
                      </div>
                    }
                    customButton="mt-3 text-sm text-left text-gray-800 dark:text-white"
                  />
                }
              >
                <ShowWordpress business={row} />
              </EmptyModalBox>

              <div className="my-2 border-t border-gray-500"></div>

              <EmptyModalBox
                width={800}
                item={
                  <Button
                    type="white"
                    label={
                      <div className="inline-flex pl-2">
                        <MdEdit className="ml-4 text-lg text-blue-900" />
                        <p className="pl-2">Edit</p>
                      </div>
                    }
                    customButton="mt-3 text-sm text-left text-gray-800 dark:text-white"
                  />
                }
              >
                <Show business={row} />
              </EmptyModalBox>

              <div className="my-2 border-t border-gray-500"></div>

              <EmptyModalBox
                item={
                  <Button
                    type="white"
                    label={
                      <div className="inline-flex pl-2">
                        <MdDelete className="ml-4 text-lg text-blue-900" />
                        <p className="pl-2">Delete</p>
                      </div>
                    }
                    customButton="mt-3 text-sm text-left text-gray-800 dark:text-white"
                  />
                }
              >
                <Delete item={row} />
              </EmptyModalBox>
            </>
          )}
        </div>
      )}
        <div className={`fixed bottom-16 right-6 mt-3 md:hidden`}>
          {auth.isOwner() && (
            <EmptyModalBox
              width={800}
              item={
                <TooltipHorizon content="">
                  <IconButton extra="rounded-3xl">
                    <MdAdd size={22} />
                  </IconButton>
                </TooltipHorizon>
              }
            >
              <Create />
            </EmptyModalBox>
          )}
        </div>
    </>
  );
};

export default MobileOptions;
