import { ApiResource } from 'apiplatform/utils/types';
import { useStreamContext } from 'components/context/StreamContext';
import { useEffect, useId, useRef } from 'react';

const useStream = <TypeRequest extends ApiResource>(iri: string) => {
  const [streamProvider, setSteamProvider] = useStreamContext();
  const id = useId();

  const abort = () => {
    if (streamProvider.abortController) {
      streamProvider.abortController.abort();
    }

    setSteamProvider((streamProvider) => ({
      ...streamProvider,
      streaming: false,
      completed: false,
      content: '',
      targetId: null,
    }));
  };

  const abortControllerRef = useRef<AbortController>(null);
  const completedRef = useRef(false);

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  return {
    streaming:
      streamProvider.targetId === id ? streamProvider.streaming : false,
    content: streamProvider.targetId === id ? streamProvider.content : '',
    completed: completedRef.current,
    abort,
    stream: (requestParameters: Partial<TypeRequest>) => {
      abort();

      const abortController = new AbortController();
      abortControllerRef.current = abortController;

      setSteamProvider({
        ...streamProvider,
        abortController: abortController,
        streaming: true,
        content: '',
        targetId: id,
      });

      const storage = localStorage.getItem('user');

      const headers = {
        'Content-Type': 'application/json',
        Authorization: '',
      };

      if (storage !== 'null') {
        const user = JSON.parse(storage);
        headers['Authorization'] = `Bearer ${user.token}`;
      }

      fetch(process.env.REACT_APP_API_URL + iri, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(requestParameters),
        signal: abortController.signal,
      })
        .then((response) => response.body)
        .then((rb) => {
          const reader = rb.getReader();

          return new ReadableStream({
            start(controller) {
              function push() {
                reader
                  .read()
                  .then(({ done, value }) => {
                    if (done) {
                      completedRef.current = true;
                      setSteamProvider((streamProvider) => ({
                        ...streamProvider,
                        streaming: false,
                        completed: true,
                        targetId: null,
                      }));
                      controller.close();
                      return;
                    }
                    controller.enqueue(value);

                    setSteamProvider((streamProvider) => ({
                      ...streamProvider,
                      content:
                        streamProvider.content +
                        new TextDecoder().decode(value),
                    }));
                    push();
                  })
                  .catch(() => {});
              }

              push();
            },
          });
        })
        .catch(() => {});
    },
  };
};

export default useStream;
