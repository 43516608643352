import IconButton from 'components/fields/IconButton';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { MdPlayArrow } from 'react-icons/md';
import { BusinessArticleTitle } from 'apiplatform/interfaces/BusinessArticleTitle';
import { scrollToTop } from 'components/scrollToTop';
import { FaSpinner } from 'react-icons/fa';

interface GenerateArticleButtonProps {
  articleTitle: BusinessArticleTitle;
  setArticleTitle: Dispatch<SetStateAction<BusinessArticleTitle>>;
  currentArticleTitle: BusinessArticleTitle;
}

const GenerateArticleStreamButton = ({
  articleTitle,
  setArticleTitle,
  currentArticleTitle,
}: GenerateArticleButtonProps) => {
  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
    if (articleTitle !== currentArticleTitle) {
      setIsClicked(false);
    }
  }, [articleTitle, currentArticleTitle]);

  return (
    <span
      onClick={() => {
        setIsClicked(true);
        setArticleTitle(articleTitle);
        scrollToTop();
      }}
    >
      <IconButton>
        {isClicked ? <FaSpinner className="animate-spin" /> : <MdPlayArrow />}
      </IconButton>
    </span>
  );
};

export default GenerateArticleStreamButton;
