import { useParams } from 'react-router-dom';
import { PagedCollection } from '../../interfaces/Collection';
import DataTableView from 'components/datatable/DataTableView';
import useCollectionUrl from 'apiplatform/hooks/collectionurl';
import Moment from 'react-moment';
import EmptyModalBox from '../base/modal/EmptyModalBox';
import TooltipHorizon from 'components/tooltip';
import IconButton from 'components/fields/IconButton';
import { MdAdd, MdDelete, MdEdit, MdError, MdPlayArrow } from 'react-icons/md';
import { BusinessTranscript } from 'apiplatform/interfaces/BusinessTranscript';
import useRetrieve from 'hooks/useRetrieve';
import Delete from './Delete';
import Show from './Show';
import { FaSpinner, FaVideo } from 'react-icons/fa';
import { Dispatch, SetStateAction } from 'react';
import { Subpage } from './List';
import ReloadOnComplete from '../businessarticletitle/ReloadOnComplete';
import { scrollToTop } from 'components/scrollToTop';
import MobileOptions from './MobileOptions';

interface TableProps {
  setSubpage: Dispatch<SetStateAction<Subpage>>;
  subpage: Subpage;
}

const Table = ({ setSubpage, subpage }: TableProps) => {
  const { id: businessId, ctid: contentThemeId } = useParams();

  const { url } = useCollectionUrl(
    `/businesses/${businessId}/transcripts?contentTheme=${contentThemeId}`,
    {
      defaultSortBy: 'createdAt',
      defaultSortOrder: 'desc',
    }
  );

  const { retrieved, loading } =
    useRetrieve<PagedCollection<BusinessTranscript>>(url);

    const getDynamicWidth = () => {
      return window.innerWidth < 576 ? '160px' : '200px';
    };

  return (
    <DataTableView
      retrieved={retrieved}
      loading={loading}
      columns={[
        {
          name: 'Transcript Title',
          id: 'title',
          sortable: true,
          cell: (row) => {
            return row.businessTranscriptStatus === 'ready' ? (
              <span
                onClick={() => {
                  setSubpage({
                    type: 'transcript-articles',
                    transcriptId: row['@id'],
                    title: row.title,
                  });
                  scrollToTop();
                }}
                className={`w-full cursor-pointer py-4 text-sm font-bold text-navy-700 dark:text-white ${
                  row['@id'] === subpage.transcriptId ? '!text-blue-500' : ''
                }`}
              >
                <p className="w-80p">{row.title}</p>
              </span>
            ) : (
              <div
                className={
                  row.businessTranscriptStatus === 'pending' ? 'opacity-50' : ''
                }
              >
                {row.title}
              </div>
            );
          },
        },
        {
          name: 'Created At',
          id: 'createdAt',
          sortable: true,
          width: '160px',
          hide: 576,
          cell: (row) => (
            <Moment
              format="MMMM Do YYYY"
              className="text-navy-700 dark:text-white"
            >
              {row.createdAt}
            </Moment>
          ),
        },
        {
          width: getDynamicWidth(),
          cell: (row) => (
            <div className="w-70p md:w-full text-right ">
              {row.businessTranscriptStatus === 'ready' && (
                <>
                  <MobileOptions
                    row={row}
                    setSubpage={setSubpage}
                    subpage={subpage}
                  />
                  {row.originalFileName && (
                    <span
                      onClick={() => {
                        setSubpage({
                          type: 'video-suggestions',
                          transcriptId: row['@id'],
                          title: row.title,
                        });
                      }}
                    >
                      <TooltipHorizon content="Generate Video Suggestions">
                        <IconButton extra="hidden md:block">
                          <FaVideo />
                        </IconButton>
                      </TooltipHorizon>
                    </span>
                  )}

                  <TooltipHorizon content="Generate Article Suggestions">
                    <span
                      onClick={() => {
                        setSubpage({
                          type: 'transcript-articles',
                          transcriptId: row['@id'],
                          title: row.title,
                        });
                        scrollToTop();
                      }}
                    >
                      <IconButton extra="hidden md:block">
                        <MdPlayArrow />
                      </IconButton>
                    </span>
                  </TooltipHorizon>

                  <EmptyModalBox
                    item={
                      <TooltipHorizon content="Edit">
                        <IconButton extra="hidden md:block">
                          <MdEdit />
                        </IconButton>
                      </TooltipHorizon>
                    }
                  >
                    <Show transcript={row} />
                  </EmptyModalBox>
                </>
              )}
              {row.businessTranscriptStatus === 'pending' && (
                <TooltipHorizon content="Transcript is being processed">
                  <ReloadOnComplete record={row} />
                  <IconButton extra="hidden md:block">
                    <FaSpinner className="animate-spin" />
                  </IconButton>
                </TooltipHorizon>
              )}
              {row.businessTranscriptStatus === 'error' && (
                <>
                  <EmptyModalBox
                    item={
                      <TooltipHorizon content="Delete">
                        <IconButton extra="hidden md:block">
                          <MdDelete />
                        </IconButton>
                      </TooltipHorizon>
                    }
                  >
                    <Delete item={row} />
                  </EmptyModalBox>
                  <TooltipHorizon content="Error processing the media">
                    <IconButton
                      extra="hidden md:block"
                      color="bg-red-500"
                      onHoverColor="hover:bg-red-600"
                    >
                      <MdError />
                    </IconButton>
                  </TooltipHorizon>
                </>
              )}
              <EmptyModalBox
                item={
                  <TooltipHorizon content="Delete">
                    <IconButton extra="hidden md:block">
                      <MdDelete />
                    </IconButton>
                  </TooltipHorizon>
                }
              >
                <Delete item={row} />
              </EmptyModalBox>
            </div>
          ),
        },
      ]}
    />
  );
};

export default Table;
