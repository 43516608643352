import { PageCard } from 'components/card/PageCard';
import DataTableView from 'components/datatable/DataTableView';
import { useParams } from 'react-router-dom';
import useRetrieve from 'hooks/useRetrieve';
import { MdAdd, MdClose } from 'react-icons/md';
import IconButton from 'components/fields/IconButton';
import TooltipHorizon from 'components/tooltip';
import EmptyModalBox from '../base/modal/EmptyModalBox';
import { BusinessArticleTitle } from 'apiplatform/interfaces/BusinessArticleTitle';
import Create from './Create';
import { IoReload } from 'react-icons/io5';
import useCreate from 'hooks/useCreate';
import { IoMdEye } from 'react-icons/io';
import { Link } from 'react-router-dom';
import { FaSpinner } from 'react-icons/fa';
import ReloadOnComplete from './ReloadOnComplete';
import { GenerateBusinessArticleTitles } from 'apiplatform/interfaces/GenerateBusinessArticleTitles';
import Loader from '../base/Loader';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { Subpage } from '../businesstranscript/List';
import GenerateArticleStreamButton from './GenerateArticleStreamButton';
import { PagedCollection } from 'apiplatform/interfaces/Collection';

interface ListProps {
  subpage: Subpage;
  articleTitle: BusinessArticleTitle;
  setArticleTitle: Dispatch<SetStateAction<BusinessArticleTitle>>;
  setSubpage: Dispatch<SetStateAction<Subpage>>;
}

const List = ({
  subpage,
  setSubpage,
  setArticleTitle,
  articleTitle,
}: ListProps) => {
  const { id, ctid } = useParams();

  let generateTitleSuggestionsRan = useRef(false);

  const { retrieved, loading } = useRetrieve<
    PagedCollection<BusinessArticleTitle>
  >(
    `/businesses/${id}/article-titles?contentTheme=${ctid}&${
      subpage.transcriptId
        ? `businessTranscript=${subpage.transcriptId}`
        : 'exists[businessTranscript]=false'
    }`
  );

  const { create: generateTitles, loading: generateTitlesLoading } =
    useCreate<GenerateBusinessArticleTitles>(
      `/businesses/${id}/business_article_titles`
    );

  const handleGenerateNewTitles = useCallback(() => {
    generateTitles({
      contentTheme: `/businesses/content_themes/${ctid}`,
      ...(subpage.transcriptId
        ? {
            businessTranscript: subpage.transcriptId,
          }
        : {}),
    });
  }, [generateTitles, ctid, subpage.transcriptId]);

  useEffect(() => {
    if (
      retrieved['@id'] !== '' &&
      retrieved['hydra:totalItems'] === 0 &&
      !generateTitleSuggestionsRan.current
    ) {
      generateTitleSuggestionsRan.current = true;
      handleGenerateNewTitles();
    }

    if (retrieved['@id'] === '') {
      generateTitleSuggestionsRan.current = false;
    }
  }, [retrieved, handleGenerateNewTitles]);

  return (
    <PageCard>
      {generateTitlesLoading ? (
        <Loader title="Article titles suggestions are being generated..." />
      ) : (
        <>
          <DataTableView
            retrieved={retrieved}
            loading={loading}
            noPagination={true}
            noRowsPerPage={true}
            noDataComponent={
              <>
                You have not generated any article suggestions yet.
                <br />
                Click <b>Generate New Article Suggestion</b> icon
                <br />
                or <b>Create Custom Article Title</b> to create suggestions.
              </>
            }
            columns={[
              {
                name: `Article suggestions for: ${subpage.title}`,
                id: 'name',
                cell: (row: BusinessArticleTitle) =>
                  row.status === 'complete' ? (
                    <Link
                      to={`/business/${id}/theme/${ctid}/queue?title=${row.title}`}
                      className="block w-full py-4 text-sm font-bold text-navy-700 dark:text-white"
                    >
                      {row.title}
                    </Link>
                  ) : (
                    <div
                      className={
                        row.id === articleTitle?.id
                          ? 'font-bold !text-blue-500'
                          : ''
                      }
                    >
                      {row.title}
                    </div>
                  ),
              },
              {
                name: (
                  <div className="w-full text-right">
                    <TooltipHorizon content="Generate New Article Titles Suggestions">
                      <span onClick={handleGenerateNewTitles}>
                        <IconButton>
                          <IoReload />
                        </IconButton>
                      </span>
                    </TooltipHorizon>
                    <EmptyModalBox
                      item={
                        <TooltipHorizon content="Create Custom Article Title">
                          <IconButton
                            color="bg-[#5a5a9c]"
                            onHoverColor="hover:bg-gray-800"
                          >
                            <MdAdd />
                          </IconButton>
                        </TooltipHorizon>
                      }
                    >
                      <Create subpage={subpage} />
                    </EmptyModalBox>
                    <TooltipHorizon
                      content={
                        <>
                          Close <b>Article Generator</b>
                        </>
                      }
                    >
                      <span
                        onClick={() => {
                          setSubpage({ type: 'none' });
                        }}
                      >
                        <IconButton extra="bg-red-500 hover:bg-red-600">
                          <MdClose />
                        </IconButton>
                      </span>
                    </TooltipHorizon>
                  </div>
                ),
                width: '200px',
                cell: (row: BusinessArticleTitle) => (
                  <div className="w-full text-right">
                    {row.status === 'new' && (
                      <>
                        <TooltipHorizon content="Generate Article">
                          <GenerateArticleStreamButton
                            articleTitle={row}
                            setArticleTitle={setArticleTitle}
                            currentArticleTitle={articleTitle}
                          />
                        </TooltipHorizon>
                        {/* <TooltipHorizon content="Generate Article in the Background">
                          <GenerateArticleBackgroundButton
                            articleId={row['@id']}
                          />
                        </TooltipHorizon> */}
                      </>
                    )}
                    {row.status === 'inprogress' && (
                      <TooltipHorizon content="Article is being generated">
                        <ReloadOnComplete record={row} />
                        <IconButton>
                          <FaSpinner className="animate-spin" />
                        </IconButton>
                      </TooltipHorizon>
                    )}
                    {row.status === 'complete' && (
                      <TooltipHorizon content="Go to Media Queue" extra="">
                        <Link
                          to={`/business/${id}/theme/${ctid}/queue?title=${row.title}`}
                        >
                          <IconButton complete={row.status === 'complete'}>
                            <IoMdEye />
                          </IconButton>
                        </Link>
                      </TooltipHorizon>
                    )}
                  </div>
                ),
              },
            ]}
          />
        </>
      )}
    </PageCard>
  );
};

export default List;
