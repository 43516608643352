import Card from '../../../components/card';

interface CreateNewProps {
  title?: string;
  description: string;
  color: string;
  label: string;
  onClick?: () => void;
  extraPadding?: string;
  className?:string
  children?: React.ReactNode;
}

const CreateNew = ({
  title,
  description,
  color,
  label,
  onClick,
  extraPadding = 'py-8',
  className,
  children,
}: CreateNewProps) => {
  return (
    <div className="relative flex sm: w-full transform transition-transform duration-300 hover:-translate-y-3">
      <Card extra={` ${extraPadding || 'py-9'} px-5`}>
        <div className="absolute right-0 top-0 z-10 cursor-default">
          <div
            className={` rounded-full px-3 py-1 text-center text-sm font-medium  text-white bg-${color}`}
          >
            {label}
          </div>
        </div>
        <div className={`${className}`}>
          {children ? (
            <div className="cursor-pointer mt-[15px]">{children}</div>
          ) : (
            <div className="cursor-pointer mt-[15px]" onClick={onClick}>
              <p className="mt-1 text-[14px] font-medium text-gray-800 sm:whitespace-nowrap  sm:leading-normal md:text-wrap">
                {title}
              </p>
              <p className="mt-1 whitespace-normal text-[12px] leading-tight text-[#636e91] sm:whitespace-nowrap sm:leading-normal md:text-wrap">
                {description}
              </p>
            </div>
          )}
        </div>
      </Card>
    </div>
  );
};

export default CreateNew;
