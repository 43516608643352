import React, { MutableRefObject } from 'react';
import Button from '../../../components/fields/Button';
import EmptyModalBox from '../base/modal/EmptyModalBox';
import TooltipHorizon from 'components/tooltip';
import Delete from '../businessarticletitle/Delete';
import Calendar from '../../../views/admin/main/applications/calendar';
import WordpressModal from '../../../Modal/WordPress/WordpressModal';
import TwitterModal from '../../../Modal/Twitter/TwitterModal';
import CopyToClipboard from '../../../Modal/CopyToClipboard';
import ContentStatus from '../../../Modal/ContentStatus';
import Dropdown from '../../../components/dropdown';
import { IoIosMore } from 'react-icons/io';
import TResource from './type';
import { PagedCollection } from '../../interfaces/Collection';
import { Editor as TinyMCEEditor } from 'tinymce';

interface MobileButtonsProps {
  article: TResource;
  wpRetrieved: PagedCollection<TResource> | null;
  editorRef: MutableRefObject<TinyMCEEditor>;
  wpLoading: boolean;
  updated: boolean;
  loading: boolean;
  content: string;
  title: string;
  onClose: () => void;
  handleToggleDropdown: () => void;
  update: (article: TResource, values: any) => void;
}

const MobileButtons: React.FC<MobileButtonsProps> = ({
  article,
  wpRetrieved,
  editorRef,
  wpLoading,
  updated,
  loading,
  content,
  title,
  onClose,
  handleToggleDropdown,
  update,
}) => {
  const ArticleStatus = article.status;

  return (
    <div className="flex w-full place-content-between justify-start lg:hidden  ">
      {/* Save Button */}
      <Button
        type="blue"
        label="Save"
        customButton={`rounded-lg ${
          loading ? 'px-6' : 'px-4'
        } py-1 mb-2  inline-block  sm:w-auto text-sm sm:text-base sm:leading-normal truncate`}
        customSpinner="absolute left-0.5 top-2 -mt-0.5"
        loading={loading}
        completed={updated && !loading}
        onClick={() => update(article, { content, title })}
      />

      {/* Copy to Clipboard */}
      <EmptyModalBox
      
        item={
          <CopyToClipboard editorRef={editorRef} title={article.title} mobile />
        }
      >
        <ContentStatus
          setArticleStatus={(status) => {
            update(article, { status });
          }}
        />
      </EmptyModalBox>

      {/* Dropdown for additional options */}
      <div className="inline-block  ">
        <Dropdown
          classNames={'py-10'}
          button={
            <TooltipHorizon content="">
              <Button
                type="blue"
                label={<IoIosMore />}
                leftMargin
                customButton="rounded-3xl px-[7px] py-[7px] w-full sm:w-auto text-sm sm:text-base truncate"
                onClick={handleToggleDropdown}
              />
            </TooltipHorizon>
          }
        >
          <div className="absolute -right-3 bottom-9 mt-2 flex text-left h-max w-40 flex-col justify-start rounded-[20px] bg-white pb-4 shadow-xl dark:bg-navy-700 dark:text-white">
            {/* Schedule Button */}
            <EmptyModalBox
              width={800}
              item={
                <TooltipHorizon
                  content={
                    wpRetrieved && wpRetrieved['@id'] === '' ? (
                      <>
                        Please Setup <b>CMS Integration</b> first
                      </>
                    ) : null
                  }
                >
                  <Button
                    type="white"
                    label="Schedule"
                    customButton="mt-3 text-sm text-left text-gray-800  dark:text-white hover:dark:text-white block"
                    leftMargin
                    disabled={wpRetrieved && wpRetrieved['@id'] === ''}
                    className={ArticleStatus === 'sent' ? 'hidden' : ''}
                  />
                </TooltipHorizon>
              }
            >
              <Calendar article={article} />
            </EmptyModalBox>

            {/* View on Website Button */}
            {ArticleStatus === 'sent' && article.url ? (
              <Button
                label="View on Website"
                type="white"
                customButton="mt-3 text-sm text-left text-gray-800 dark:text-white hover:dark:text-white"
                leftMargin
                onClick={() => window.open(article.url, '_blank')}
              />
            ) : wpLoading || article.status === 'sent' ? null : (
              <EmptyModalBox
                item={
                  <TooltipHorizon
                    content={
                      wpRetrieved && wpRetrieved['@id'] === '' ? (
                        <>
                          Please Setup <b>CMS Integration</b> first
                        </>
                      ) : null
                    }
                  >
                    <Button
                      label="Publish Now"
                      customButton="mt-3 text-sm text-left text-gray-800  dark:text-white hover:dark:text-white"
                      type="white"
                      disabled={wpRetrieved && wpRetrieved['@id'] === ''}
                      leftMargin
                      className={ArticleStatus === 'sent' ? 'hidden' : ''}
                    />
                  </TooltipHorizon>
                }
              >
                <WordpressModal
                  schedule={false}
                  article={article}
                  wordpress={wpRetrieved}
                  articleScheduled={article}
                />
              </EmptyModalBox>
            )}

            {/* Social Media Share Button */}
            <EmptyModalBox
              item={
                <Button
                  type="white"
                  label="Social Media Share"
                  leftMargin
                  customButton="mt-3 text-sm  text-gray-800 dark:text-white hover:dark:text-white"
                />
              }
            >
              <TwitterModal article={article} articleScheculed={article} />
            </EmptyModalBox>

            {/* Delete Button */}
            <EmptyModalBox
              item={
                <Button
                  type="white"
                  label={
                    article.type === 'audio' || article.type === 'video'
                      ? 'Delete Clip'
                      : 'Delete Article'
                  }
                  leftMargin
                  customButton="mt-3 text-sm text-gray-800 dark:text-white hover:dark:text-white"
                />
              }
            >
              <Delete article={article} close={onClose} />
            </EmptyModalBox>
          </div>
        </Dropdown>
      </div>
    </div>
  );
};

export default MobileButtons;
