import Dropdown from 'components/dropdown';
import { Link } from 'react-router-dom';
import { FaUserCircle } from 'react-icons/fa';
import { useAuthContext } from 'components/auth/AuthProvider';
import Breadcrumbs from 'apiplatform/components/breadcrumbs/Breadcrumbs';
import EmptyModalBox from '../../apiplatform/components/base/modal/EmptyModalBox';
import TooltipHorizon from '../tooltip';
import ShowTutorialsMenu from 'apiplatform/components/tutorials/ShowTutorialsMenu';
import ThemeDisplay from './ThemeDisplay';

const Navbar = (props: {
  onOpenSidenav: () => void;
  brandText: string;
  secondary?: boolean | string;
  [x: string]: any;
}) => {
  const {
    // onOpenSidenav,
    brandText,
    mini,
    // setMini,
    // theme,
    // setTheme,
    hovered,
  } = props;
  // const [darkmode, setDarkmode] = React.useState(
  //   document.body.classList.contains('dark')
  // );

  const auth = useAuthContext();

  const handleLogOut = () => {
    auth.signOut();
  };

  return (
    <nav
      className={`duration-175 linear fixed right-3 top-3 flex flex-row flex-wrap items-center justify-between rounded-xl bg-white/30 transition-all ${
        mini === false
          ? 'w-[calc(100vw_-_6%)] md:w-[calc(100vw_-_8%)] lg:w-[calc(100vw_-_6%)] xl:w-[calc(100vw_-_350px)] 2xl:right-[50%] 2xl:mr-[-632px] 2xl:w-[973px]'
          : mini === true && hovered === true
          ? 'w-[calc(100vw_-_6%)] md:w-[calc(100vw_-_8%)] lg:w-[calc(100vw_-_6%)] xl:w-[calc(100vw_-_350px)] 2xl:w-[calc(100vw_-_365px)]'
          : 'w-[calc(100vw_-_6%)] md:w-[calc(100vw_-_8%)] lg:w-[calc(100vw_-_6%)] xl:w-[calc(100vw_-_180px)] 2xl:w-[calc(100vw_-_195px)]'
      } h-[76px]  backdrop-blur-xl dark:bg-[#0b14374d] md:right-[30px] md:top-4 xl:top-4`}
    >
      <div className="pl-4 pt-[5px]">
        <Breadcrumbs />
        <p className="shrink text-[24px] capitalize text-navy-700 dark:text-white sm:text-[24px] md:text-[33px] lg:text-[33px]">
          <Link
            to="#"
            className="font-bold capitalize hover:text-navy-700 dark:hover:text-white"
          >
            <ThemeDisplay brandText={brandText} />
          </Link>
        </p>
      </div>

      <div
        className="relative flex h-[50px] w-auto flex-grow-0 cursor-pointer items-center justify-around gap-2 rounded-[20px]
       bg-white px-4 py-1 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none 
       sm:h-[55px] sm:w-auto sm:px-6 sm:py-2 
       md:h-[61px] md:w-[100px] 
       xl:w-[100px]"
      >
        {/* <span
          className="flex cursor-pointer text-xl text-[#636e91] dark:text-white xl:hidden "
          onClick={onOpenSidenav}
        >
          <FiAlignJustify className="h-5 w-5" />
        </span> */}
        {/* start Notification */}
        {/* <MdNotifications />
        <MdSettings />
        <MdImage /> */}
        {/* <Dropdown
          button={
            <p className="cursor-pointer">
              <IoMdNotificationsOutline className="h-4 w-4 text-[#636e91] dark:text-white" />
            </p>
          }
          animation="origin-[65%_0%] md:origin-top-right transition-all duration-300 ease-in-out"
          children={
            <div className="flex w-[360px] flex-col gap-3 rounded-[20px] bg-white p-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none sm:w-[460px]">
              <div className="flex items-center justify-between">
                <p className="text-base font-bold text-navy-700 dark:text-white">
                  Notification
                </p>
                <p className="text-sm font-bold text-navy-700 dark:text-white">
                  Mark all read
                </p>
              </div>

              <button className="flex w-full items-center">
                <div className="flex h-full w-[85px] items-center justify-center rounded-xl bg-gradient-to-b from-brand-400 to-brand-500 py-4 text-2xl text-white">
                  <BsArrowBarUp />
                </div>
                <div className="ml-2 flex h-full w-full flex-col justify-center rounded-lg px-1 text-sm">
                  <p className="mb-1 text-left text-base font-bold text-gray-900 dark:text-white">
                    New Update: Horizon UI Dashboard PRO
                  </p>
                  <p className="font-base text-left text-xs text-gray-900 dark:text-white">
                    A new update for your downloaded item is available!
                  </p>
                </div>
              </button>

              <button className="flex w-full items-center">
                <div className="flex h-full w-[85px] items-center justify-center rounded-xl bg-gradient-to-b from-brand-400 to-brand-500 py-4 text-2xl text-white">
                  <BsArrowBarUp />
                </div>
                <div className="ml-2 flex h-full w-full flex-col justify-center rounded-lg px-1 text-sm">
                  <p className="mb-1 text-left text-base font-bold text-gray-900 dark:text-white">
                    New Update: Horizon UI Dashboard PRO
                  </p>
                  <p className="font-base text-left text-xs text-gray-900 dark:text-white">
                    A new update for your downloaded item is available!
                  </p>
                </div>
              </button>
            </div>
          }
          classNames={"py-2 top-4 -left-[230px] md:-left-[440px] w-max"}
        /> */}
        {/* start Horizon PRO */}
        {/* <Dropdown
          button={
            <p className="cursor-pointer">
              <IoMdInformationCircleOutline className="h-4 w-4 text-[#636e91] dark:text-white" />
            </p>
          }
          children={
            <div className="flex w-[350px] flex-col gap-2 rounded-[20px] bg-white p-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
              <div
                style={{
                  backgroundImage: `url(${navbarimage})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
                className="mb-2 aspect-video w-full rounded-lg"
              />
              <a
                target="blank"
                href="https://horizon-ui.com/pro?ref=live-pro-tailwind-react"
                className="px-full linear flex cursor-pointer items-center justify-center rounded-xl bg-brand-500 py-[11px] font-bold text-white transition duration-200 hover:bg-brand-600 hover:text-white active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:bg-brand-200"
              >
                Buy Horizon UI PRO
              </a>
              <a
                target="blank"
                href="https://horizon-ui.com/docs-tailwind/docs/react/installation?ref=live-pro-tailwind-react"
                className="px-full linear flex cursor-pointer items-center justify-center rounded-xl border py-[11px] font-bold text-navy-700 transition duration-200 hover:bg-gray-200 hover:text-navy-700 dark:!border-white/10 dark:text-white dark:hover:bg-white/20 dark:hover:text-white dark:active:bg-white/10"
              >
                See Documentation
              </a>
              <a
                target="blank"
                href="https://horizon-ui.com/?ref=live-pro-tailwind-react"
                className="hover:bg-black px-full linear flex cursor-pointer items-center justify-center rounded-xl py-[11px] font-bold text-navy-700 transition duration-200 hover:text-navy-700 dark:text-white dark:hover:text-white"
              >
                Try Horizon Free
              </a>
            </div>
          }
          classNames={"py-2 top-6 -left-[250px] md:-left-[330px] w-max"}
          animation="origin-[75%_0%] md:origin-top-right transition-all duration-300 ease-in-out"
        /> */}
        {/* <div
          className="cursor-pointer text-[#636e91]"
          onClick={() => {
            if (darkmode) {
              document.body.classList.remove('dark');
              setDarkmode(false);
            } else {
              document.body.classList.add('dark');
              setDarkmode(true);
            }
          }}
        >
          {darkmode ? (
            <RiSunFill className="h-4 w-4 text-[#636e91] dark:text-white" />
          ) : (
            <RiMoonFill className="h-4 w-4 text-[#636e91] dark:text-white" />
          )}
        </div> */}
        {/* <Configurator
          mini={props.mini}
          setMini={props.setMini}
          theme={props.theme}
          setTheme={props.setTheme}
          darkmode={darkmode}
          setDarkmode={setDarkmode}
        /> */}
        {/* <div
          className="cursor-pointer text-[#636e91]"
          onClick={() => {
            if (darkmode) {
              document.body.classList.remove("dark");
              setDarkmode(false);
            } else {
              document.body.classList.add("dark");
              setDarkmode(true);
            }
          }}
        ></div> */}
        {/* Profile & Dropdown */}
        <Dropdown
          button={
            <FaUserCircle className="sm:text-[33px]text-navy-700 md:text-[33px] lg:text-[33px]" />
          }
          children={
            <div className="flex h-max w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat pb-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
              <div className="ml-4 mt-3">
                <div className="flex items-center gap-2">
                  <p className="overflow-hidden text-sm font-bold text-navy-700 dark:text-white">
                    {auth.getEmail().substring(0, 20)}
                    {auth.getEmail().length >= 20 && '...'}
                  </p>
                </div>
              </div>
              <div className="mt-3 h-px w-full bg-gray-200 dark:bg-white/20 " />

              <div className="ml-4 mt-3 flex flex-col">
                {/* <a
                  href=" "
                  className="text-sm text-gray-800 dark:text-white hover:dark:text-white"
                >
                  Profile Settings
                </a>
                <a
                  href=" "
                  className="mt-3 text-sm text-gray-800 dark:text-white hover:dark:text-white"
                >
                  Newsletter Settings
                </a> */}
                {auth.isOwner() && (
                  <Link
                    to="/managers"
                    className="mt-3 text-sm text-gray-800 dark:text-white hover:dark:text-white"
                  >
                    User Manager
                  </Link>
                )}
                <EmptyModalBox
                  width={800}
                  item={
                    <TooltipHorizon content="">
                      <p className="mt-3 text-sm text-gray-800 dark:text-white hover:dark:text-white">
                        Tutorials &amp; How-to Videos
                      </p>
                    </TooltipHorizon>
                  }
                >
                  <ShowTutorialsMenu />
                </EmptyModalBox>
                <Link
                  to="https://sites.google.com/view/changelogcustomergain/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mt-3 text-sm text-gray-800 dark:text-white hover:dark:text-white"
                >
                  Changelog
                </Link>

                <span
                  className="mt-3 cursor-pointer text-sm font-medium text-red-500 hover:text-red-500"
                  onClick={handleLogOut}
                >
                  Log Out
                </span>
              </div>
            </div>
          }
          classNames={'py-2 top-8 -left-[180px] w-max'}
        />
      </div>
    </nav>
  );
};

export default Navbar;
