import { BusinessArticleTitle } from 'apiplatform/interfaces/BusinessArticleTitle';
import Show from '../businessarticle/Show';
import { useParams } from 'react-router-dom';
import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Editor as TinyMCEEditor } from 'tinymce';
import useStream from 'hooks/useStream';
import { GenerateBusinessArticleStream } from 'apiplatform/interfaces/GenerateBusinessArticleStream';
import Button from 'components/fields/Button';
import useRetrieve from 'hooks/useRetrieve';
import { BusinessArticle } from 'apiplatform/interfaces/BusinessArticle';
import { PagedCollection } from 'apiplatform/interfaces/Collection';

interface ArticleStreamProps {
  articleTitle: BusinessArticleTitle;
  setArticleTitle: Dispatch<SetStateAction<BusinessArticleTitle>>;
}

const ArticleStream = ({
  articleTitle,
  setArticleTitle,
}: ArticleStreamProps) => {
  const { id } = useParams();
  const editorRef = useRef<TinyMCEEditor | null>(null);
  const streamRequestSentRef = useRef(false);
  const queryClient = useQueryClient();

  const {
    stream,
    streaming,
    content: streamContent,
    abort,
    completed,
  } = useStream<GenerateBusinessArticleStream>(
    `/businesses/${id}/generate_article/${articleTitle.id}`
  );

  const { retrieved } = useRetrieve<PagedCollection<BusinessArticle>>(
    `/businesses/${id}/articles?perPage=1&businessArticleTitle=${articleTitle.id}`
  );

  useEffect(() => {
    if (!articleTitle.businessArticle && !streamRequestSentRef.current) {
      streamRequestSentRef.current = true;
      stream({});
    }
  }, [stream, articleTitle.businessArticle]);

  useEffect(() => {
    if (streaming && editorRef.current) {
      editorRef.current.setContent(streamContent);
    }
  }, [streaming, streamContent, editorRef]);

  useEffect(() => {
    if (completed) {
      queryClient.invalidateQueries({
        queryKey: ['businesses'],
      });
    }
  }, [completed, queryClient]);

  return (
    <div className="mb-5"> 
      <Show
        article={
          retrieved && retrieved['hydra:member'] && retrieved['hydra:member'][0]
            ? retrieved['hydra:member'][0]
            : {
                '@id': '',
                title: articleTitle.title,
                content: '',
                type: 'article',
              }
        }
        wpLoading={false}
        wpRetrieved={null}
        onClose={() => {
          abort();
          setArticleTitle(null);
        }}
        customEditorRef={editorRef}
        liveStream
        bottomArea={
          retrieved &&
          retrieved['hydra:member'] &&
          retrieved['hydra:member'][0] ? null : (
            <div className="text-center">
              <Button
                type="red"
                label="Stop Article Generation"
                customButton="rounded-lg px-4 py-1"
                customSpinner="absolute left-0.5 top-2 -mt-0.5"
                onClick={() => {
                  abort();
                  setArticleTitle(null);
                }}
              />
            </div>
          )
        }
      />
    </div>
  );
};

export default ArticleStream;
