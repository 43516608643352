import {
  Dispatch,
  DragEvent,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { toBlobURL } from '@ffmpeg/util';
import Progress from 'components/progress';
import { MdClose, MdFileUpload } from 'react-icons/md';
import { PageCard } from '../../../../../components/card/PageCard';
import StepBar from './StepBar';
import Loader from 'apiplatform/components/base/Loader';
import { useParams, useSearchParams } from 'react-router-dom';
import { Subpage } from 'apiplatform/components/businesstranscript/List';
import IconButton from 'components/fields/IconButton';
import TooltipHorizon from 'components/tooltip';
import useMedia from 'hooks/useMedia';
import VideoUploadButton from './VideoUploadButton';
import { BusinessTranscript } from 'apiplatform/interfaces/BusinessTranscript';
import { useSelectedVideo } from './Video/SelectedVideoContext';
import EmptyModalBox from 'apiplatform/components/base/modal/EmptyModalBox';
import Create from 'apiplatform/components/businesstranscript/Create';

interface VideoTranscoderProps {
  setSubpage: Dispatch<SetStateAction<Subpage>>;
}

const VideoTranscoder = ({ setSubpage }: VideoTranscoderProps) => {
  const [totalTimeS, setTotalTimeS] = useState(0);
  const [progress, setProgress] = useState(0);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [wasmLoaded, setWasmLoaded] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const steps = ['Select File', 'Processing', 'Complete'];
  const [selectedVideo, setSelectedVideo] = useSelectedVideo();

  const { id: businessId, ctid: contentThemeId } = useParams<{
    id: string;
    ctid: string;
  }>();

  const ffmpegRef = useRef(new FFmpeg());

  const { create, created } = useMedia<BusinessTranscript>(
    '/businesses/transcript_audios'
  );

  useEffect(() => {
    if (created) {
      setSubpage({ type: 'none' });
      setSelectedVideo({ ...selectedVideo, transcriptId: created['@id'] });
      searchParams.set('title', created.title);
      setSearchParams(searchParams);
    }
  }, [
    created,
    setSubpage,
    searchParams,
    setSearchParams,
    setSelectedVideo,
    selectedVideo,
  ]);

  const stopPropagation = (evt: DragEvent<HTMLDivElement>) => {
    evt.stopPropagation();
    evt.preventDefault();
  };

  const dropHandler = async (evt: DragEvent<HTMLDivElement>) => {
    const file = evt.dataTransfer.files[0];
    stopPropagation(evt);
    startTranscoding(file);
    setFileUploaded(true);
  };

  const startTranscoding = async (file: File) => {
    setCurrentStep((step) => step + 1);
    setSelectedVideo({ ...selectedVideo, file: file });
    await loadFfmpeg();
    try {
      await transcodeFile(file.name, new Uint8Array(await file.arrayBuffer()));
    } catch (error) {
      console.log(error);
    }
  };

  const loadFfmpeg = async () => {
    const baseURL = 'https://unpkg.com/@ffmpeg/core@0.12.4/dist/umd';
    const ffmpeg = ffmpegRef.current;

    await ffmpeg.load({
      coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, 'text/javascript'),
      wasmURL: await toBlobURL(
        `${baseURL}/ffmpeg-core.wasm`,
        'application/wasm'
      ),
    });

    setWasmLoaded(true);

    ffmpeg.on('log', ({ message }) => {
      console.log(message);
      const duration = message.match(/Duration:\s+([0-9:]+)/);
      if (duration) {
        const [dh, dm, ds] = duration[1].split(':');
        setTotalTimeS(Number(ds) + 60 * Number(dm) + 60 * 60 * Number(dh));
      }
      const time = message.match(/time=([0-9:]+)/);
      if (time) {
        const [th, tm, ts] = time[1].split(':');
        setProgress(Number(ts) + 60 * Number(tm) + 60 * 60 * Number(th));
      }
    });
  };

  const transcodeFile = async (fileName: string, inputData: Uint8Array) => {
    const ffmpeg = ffmpegRef.current;

    await ffmpeg.writeFile(fileName, inputData);
    await ffmpeg.exec([
      '-i',
      fileName,
      '-b:a',
      '32k',
      '-fs',
      '25M',
      'output.mp3',
    ]);
    const data = await ffmpeg.readFile('output.mp3');

    setCurrentStep((step) => step + 1);

    create([
      {
        name: 'file',
        blobValue: new Blob([data], { type: 'audio/mp3' }),
        filename: 'audio.mp3',
      },
      {
        name: 'business',
        blobValue: `/businesses/${businessId}`,
      },
      {
        name: 'contentTheme',
        blobValue: `/businesses/content_themes/${contentThemeId}`,
      },
      {
        name: 'originalFileName',
        blobValue: fileName,
      },
    ]);
  };

  const selectFile = async () => {
    if (!fileUploaded) {
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.click();

      input.onchange = (evt) => {
        if (evt.target instanceof HTMLInputElement) {
          startTranscoding(evt.target.files[0]);
        }
      };
    }
  };

  const renderContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <>
            <PageCard>
              <h2 className="text-lg font-bold text-blue-900 lg:text-2xl">
                Generate Transcript &amp; Suggestions
              </h2>
              <br />
              <p className="text-sm md:text-base">
                Once processed we will analyze your file and suggest content &
                article ideas. Processing times depend on your file size. For
                example, a 90MB video can take up to 2 hours to process.
              </p>
              <div
                onDrop={dropHandler}
                onDragEnter={stopPropagation}
                onDragOver={stopPropagation}
                onDragLeave={stopPropagation}
                onClick={selectFile}
              >
                <VideoUploadButton
                  icon={
                    <MdFileUpload className="text-[80px] text-blue-900 dark:text-white" />
                  }
                  title="Select File"
                  description={
                    <>
                      supported file types: <b>mp4 mkv avi m4a mp3</b>
                      <br />
                      max file size: <b>2GB</b>
                      <br />
                      drop your file here or click to select a file
                    </>
                  }
                />
              </div>
              {/* <div className="mt-10 text-center">
                To create transcript manually{' '}
                <EmptyModalBox
                  item={
                    <span className="cursor-pointer text-brand-500">
                      click here
                    </span>
                  }
                >
                  <Create />
                </EmptyModalBox>
              </div> */}
            </PageCard>
          </>
        );
      case 2:
        return (
          <PageCard>
            {!wasmLoaded && (
              <VideoUploadButton
                icon={<Loader />}
                title="Please wait..."
                description={
                  <>
                    We are loading the necessary tools
                    <br />
                    to process your file.
                  </>
                }
              />
            )}
            {wasmLoaded && (
              <>
                <VideoUploadButton
                  icon={<Loader />}
                  title="Processing..."
                  footer={
                    <div className="m-4 w-[90%]">
                      <Progress
                        value={
                          totalTimeS > 0 ? (100 * progress) / totalTimeS : 0
                        }
                      />
                    </div>
                  }
                  description={
                    totalTimeS > 0
                      ? `${Math.round((10000 * progress) / totalTimeS) / 100}%`
                      : ''
                  }
                />
              </>
            )}
          </PageCard>
        );
      case 3:
        return (
          <PageCard>
            <VideoUploadButton
              icon={<Loader />}
              title="Generating the transcript..."
              description={
                <>
                  We are currently sending your file to our servers
                  <br />
                  to generate the transcript.
                </>
              }
            />
          </PageCard>
        );
      default:
        return null;
    }
  };
  return (
    <div className="mb-[520px] md:mb-[450px] lg:mb-[400px]">
      <div className="relative col-span-5 h-full w-full rounded-xl bg-lightPrimary dark:!bg-navy-700 2xl:col-span-6">
        <span
          className="absolute right-4 top-4"
          onClick={() => {
            setSubpage({ type: 'none' });
          }}
        >
          <TooltipHorizon content="Close Video Transcoder">
            <IconButton extra="bg-red-500 hover:bg-red-600">
              <MdClose />
            </IconButton>
          </TooltipHorizon>
        </span>
        <div className="order-last flex items-center justify-center rounded-3xl bg-gradient-to-br from-blue-700 to-blue-900 bg-cover  bg-no-repeat px-10 py-32">
          <StepBar steps={steps} currentStep={currentStep} />
        </div>
        <div className="absolute left-1/2 top-64 order-first h-full w-90p  -translate-x-1/2 transform items-center">
          <div className="w-full ">
            {renderContent()}
          </div>{' '}
        </div>
      </div>
    </div>
  );
};

export default VideoTranscoder;
