import { useState } from 'react';
import TooltipHorizon from '../../../components/tooltip';
import { PageCard } from 'components/card/PageCard';
import Header from '../base/Header';

const ShowTutorialsMenu = () => {
  const videos: { [key: string]: string } = {
    'Creating and Editing a Business': 'epeakZfH1G8',
    'Content Queue Page Overview': 'HksQw62Na0M',
    '3 Ways to generate an Article': 'HYZC_L826To',
    'Edit, Schedule, Publish an Article': 'TZB2UTbpCjw',
    'AI Image Generator': '1ikQtAp3GPg',
    'Video Highlights - Generating short video clips from a longer video':
      'Tqvr0y_TfA8',
    'Content Themes (Advanced)': 'T9OAcRaqLRE',
    'Prompt Settings (Advanced)': 'RVeJtFUVJmI',
    'WordPress Integration': 'rzEqIzZaxfM',
    'User Management': 'xd1I03YjeVg',
  };

  const [selectedVideo, setSelectedVideo] = useState<string | null>(null);

  return (
    <PageCard>
      <Header
        text={selectedVideo ? selectedVideo : 'Tutorials & How-to Videos'}
      />
      <div className="flex flex-col py-6">
        {!selectedVideo && (
          <>
            <div className="ml-4 flex flex-col sm:ml-8">
              {Object.keys(videos).map((title) => (
                <p
                  className="my-2 cursor-pointer  text-sm font-bold text-navy-700 sm:text-base"
                  onClick={() => {
                    setSelectedVideo(title);
                  }}
                >
                  <TooltipHorizon content="Click to play the video" div>
                    {title}
                  </TooltipHorizon>
                </p>
              ))}
            </div>
            <div className="mt-8 md:flex md:items-center md:space-x-2">
              <p className=" text-sm text-blue-900 sm:mt-12 sm:text-base md:mt-0">
                Can't find what you are looking for?
              </p>
              <p className="sm:ml-8 text-sm sm:text-base inline-flex flex-wrap md:ml-2">
                Email: &nbsp;
                <a
                  href="mailto:thomas@customergain.com"
                  target="_blank"
                  rel="noreferrer"
                  className="text-blue-600"
                >
                  cs@customergain.com
                </a>
              </p>
            </div>
          </>
        )}
        {selectedVideo && (
          <>
            <iframe
              className="mx-auto block h-[200px] w-[300px] sm:h-[400px] sm:w-[700px]"
              src={`https://www.youtube.com/embed/${videos[selectedVideo]}`}
              title={selectedVideo}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
            <div
              className="mt-4 cursor-pointer text-center text-sm text-blue-600 sm:mt-8 sm:text-base"
              onClick={() => {
                setSelectedVideo(null);
              }}
            >
              Go Back to Tutorials &amp; How-to Videos
            </div>
          </>
        )}
      </div>
    </PageCard>
  );
};

export default ShowTutorialsMenu;
