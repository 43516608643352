import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

interface StreamContextData {
  abortController?: AbortController;
  targetId?: string;
  streaming: boolean;
  content: string;
  completed: boolean;
}

const StreamContext =
  createContext<
    [StreamContextData, Dispatch<SetStateAction<StreamContextData>>]
  >(null);

export const StreamProvider = ({ children }: PropsWithChildren) => {
  const [streamContextData, setStreamContextData] = useState<StreamContextData>(
    {
      streaming: false,
      content: '',
      completed: false,
    }
  );

  return (
    <StreamContext.Provider value={[streamContextData, setStreamContextData]}>
      {children}
    </StreamContext.Provider>
  );
};

export const useStreamContext = () => useContext(StreamContext);
