import Form from './Form';
import ErrorAlert from 'components/alert/ErrorAlert';
import useCreate from 'hooks/useCreate';
import { Business } from 'apiplatform/interfaces/Business';
import { BusinessWordpressConfig } from 'apiplatform/interfaces/BusinessWordpressConfig';
import Button from 'components/fields/Button';
import { useEffect } from 'react';

interface CreateProps {
  business: Business;
  close: () => void;
}

const Create = ({ business, close }: CreateProps) => {
  const { created, loading, error, create } =
    useCreate<BusinessWordpressConfig>(
      `/businesses/business_wordpress_configs`
    );

  useEffect(() => {
    if (created) {
      close();
    }
  }, [created, close]);

  return (
    <>
      {error && <ErrorAlert message={error.message} />}
      <Form
        onSubmit={create}
        error={error}
        label="Save"
        loading={loading}
        initialValues={{ business: business['@id'] }}
        footer={
          <Button leftMargin='modal' type="blue" label="Cancel" customButton='py-2 px-3 rounded-lg md:px-4 rounded-xl md:py-3 mb-2 w-20 inline-block  text-sm sm:text-base sm:leading-normal ' onClick={close} />
        }
      />
    </>
  );
};

export default Create;
