// MobileOptions
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { IoMdEye } from 'react-icons/io';
import { MdEdit, MdDelete, MdAdd } from 'react-icons/md';
import { IoMenu } from 'react-icons/io5';
import { ContentTheme } from 'apiplatform/interfaces/ContentTheme';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from 'components/auth/AuthProvider';
import Button from '../../../components/fields/Button';
import EmptyModalBox from '../base/modal/EmptyModalBox';
import Delete from './Delete';
import TooltipHorizon from '../../../components/tooltip';
import IconButton from '../../../components/fields/IconButton';
import Create from './Create';
import Update from './Update';
import { FaHandPointUp } from 'react-icons/fa';

interface MobileOptionsProps {
    row: ContentTheme;
    mode: 'switcher' | 'list';
    id: string; // ID of the business
    targetPath?: string; // Optional target path
    setOpened?: Dispatch<SetStateAction<boolean>>; // Optional state handler
  }
  

const MobileOptions: React.FC<MobileOptionsProps> = ({ row, mode, id, targetPath, setOpened }) => {
  const navigate = useNavigate();
  const auth = useAuthContext();
  const [menuVisible, setMenuVisible] = useState(false);

  const handleToggleMenu = () => setMenuVisible(!menuVisible);
  const closeMenu = () => setMenuVisible(false);

  return (
    <>
      <Button
        type="blue"
        label={<IoMenu className="text-base" />}
        customButton="rounded-lg px-[8px] py-[8px] w-full w-min text-sm sm:text-base truncate md:hidden"
        onClick={handleToggleMenu}
      />

      {menuVisible && (
        <div
          className={`border-3 fixed bottom-0 left-0 right-0 !z-10 mx-auto flex w-full flex-col justify-start rounded-t-[20px]  
            border bg-white pb-4 text-left shadow-2xl dark:bg-navy-700 dark:text-white md:hidden`}
        >
          <p className="my-2 pl-6 text-gray-500">{row.name}</p>

          <Button
            type="white"
            label={
              <div className="my-2 inline-flex pl-2">
                <FaHandPointUp className="ml-4 text-lg text-blue-900" />
                <p className="pl-2">Generate Content for this Theme</p>
              </div>
            }
            customButton="mt-3 text-sm text-left text-gray-800 dark:text-white"
            onClick={() => {
              navigate(
                `/business/${id}/theme/${row.id}/${
                        targetPath === undefined ? 'transcripts' : targetPath
                      }`
              );
              if (setOpened) {
                setOpened(false);
              }
            }}
          />

          <div className="my-2 border-t border-gray-500"></div>

          <EmptyModalBox
            item={
              <Button
                type="white"
                label={
                  <div className="inline-flex pl-2">
                    <MdEdit className="ml-4 text-lg text-blue-900" />
                    <p className="pl-2">Edit</p>
                  </div>
                }
                customButton="mt-3 text-sm text-left text-gray-800 dark:text-white"
              />
            }
          >
            <Update retrieved={row} />
          </EmptyModalBox>

          {!row.defaultTheme && mode === 'list' && (
            <>
              <div className="my-2 border-t border-gray-500"></div>

              <EmptyModalBox
                item={
                  <Button
                    type="white"
                    label={
                      <div className="inline-flex pl-2">
                        <MdDelete className="ml-4 text-lg text-blue-900" />
                        <p className="pl-2">Delete</p>
                      </div>
                    }
                    customButton="mt-3 text-sm text-left text-gray-800 dark:text-white"
                  />
                }
              >
                <Delete item={row} />
              </EmptyModalBox>
            </>
          )}
        </div>
      )}
      <div className="fixed bottom-16 right-6 mt-3 md:hidden ">
        <EmptyModalBox
          item={
            <TooltipHorizon content="">
              <IconButton extra="rounded-3xl">
                <MdAdd size={22} />
              </IconButton>
            </TooltipHorizon>
          }
        >
          <Create />
        </EmptyModalBox>
      </div>
    </>
  );
};

export default MobileOptions;
