import React, { useState, useRef, useLayoutEffect } from 'react';
import { IoIosArrowDown } from 'react-icons/io';

interface TimeDropdownProps {
  options: string[];
  selectedTime: string;
  onChange: (time: string) => void;
  onClose?: () => void;
}

const TimeDropdown = ({
  options,
  selectedTime,
  onChange,
  onClose,
}: TimeDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownListRef = useRef<HTMLUListElement | null>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const optionRefs = useRef<(HTMLLIElement | null)[]>([]);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const handleOptionSelect = (time: string) => {
    onChange(time);
    setIsOpen(false);
    if (onClose) onClose();
  };

  useLayoutEffect(() => {
    if (isOpen && dropdownListRef.current) {
      const selectedItemIndex = options.indexOf(selectedTime);

      if (selectedItemIndex === -1) {
        console.error(`Selected time '${selectedTime}' not found in options.`);
        return;
      }

      const selectedRef = optionRefs.current[selectedItemIndex];
      if (selectedRef) {
        selectedRef.scrollIntoView({
          behavior: 'auto',
          block: 'center',
        });
      }
    }
  }, [isOpen, selectedTime, options]);

  useLayoutEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
        if (onClose) onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  return (
    <div className="relative" ref={dropdownRef}>
      <div
        className="flex w-75p cursor-pointer bg-white p-2"
        onClick={toggleDropdown}
      >
        {selectedTime} <IoIosArrowDown className='ml-2'/>
      </div>

      {isOpen && (
        <div
          className="absolute max-h-40 w-80p overflow-auto border bg-white  "
          style={{ zIndex: 10 }}
        >
          <ul ref={dropdownListRef} className="m-0 list-none p-0">
            {options.map((time, idx) => (
              <li
                key={idx}
                ref={(el) => (optionRefs.current[idx] = el)}
                className={`cursor-pointer p-2 text-base hover:bg-gray-200 ${
                  time === selectedTime ? 'bg-blue-100' : ''
                }`}
                onClick={() => handleOptionSelect(time)}
              >
                {time}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default TimeDropdown;
