import Card from 'components/card';
import TooltipHorizon from 'components/tooltip';
import IconButton from 'components/fields/IconButton';
import { HiOutlineSwitchVertical } from 'react-icons/hi';
import { PropsWithChildren, useEffect, useState } from 'react';
import UnstyledList from './Table';
import { useParams, useSearchParams } from 'react-router-dom';
import useRetrieve from 'hooks/useRetrieve';
import { ContentTheme } from 'apiplatform/interfaces/ContentTheme';
import { MdClear } from 'react-icons/md';

interface SwitcherProps extends PropsWithChildren {
  targetPath?: string;
  hide?: boolean;
}

const Switcher = ({ children, targetPath, hide }: SwitcherProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [opened, setOpened] = useState<boolean>(false);

  const seachTitle = searchParams.get('title');
  const [filterTitle, setFilterTitle] = useState(seachTitle || '');

  const { ctid } = useParams();
  const { loading, retrieved } = useRetrieve<ContentTheme>(
    `/businesses/content_themes/${ctid}`
  );

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      searchParams.set('title', filterTitle);
      setSearchParams(searchParams);
    }, 500);
    return () => clearTimeout(timeOutId);
  }, [filterTitle, setSearchParams, searchParams]);

  useEffect(() => {
    setFilterTitle(seachTitle ?? '');
  }, [seachTitle, setFilterTitle]);

  return (
    <>
      {!hide && (
        <Card extra="w-full relative h-full p-4 mb-5 sm:overflow-x-auto text-right">
          {!opened && (
            <div className="grid grid-cols-2 grid-rows-2 md:grid-rows-1">
              <div
                className="row-start-1 ml-[16px] flex items-center pt-2 text-left"
                onClick={() => setOpened((state) => !state)}
              >
                <TooltipHorizon
                  content={
                    <>
                      Click to choose <b>Theme</b> for your new <b>Media</b>
                    </>
                  }
                >
                  <span
                    className={`leading-[29px] ${opened ? 'opacity-25' : ''}`}
                  >
                    <span className="inline-flex items-center pt-1 sm-max:text-sm md:text-base">
                      Theme:{' '}
                      {searchParams.get('allThemes') ? (
                        <span className="ml-2 w-auto whitespace-nowrap">
                          <b>All Themes</b>
                        </span>
                      ) : loading && retrieved['@id'] === '' ? (
                        '...'
                      ) : (
                        <b className="ml-2">{retrieved.name}</b>
                      )}
                    </span>
                  </span>
                </TooltipHorizon>
                <span className="h-[29px] w-[29px]">
                  <IconButton>
                    <HiOutlineSwitchVertical />
                  </IconButton>
                </span>
              </div>

              <div className="relative col-span-2 col-start-1 row-start-2 cursor-pointer justify-end pr-4 sm:pr-0 md:col-span-1 md:col-start-2 md:row-start-1">
                <div className="relative flex items-center pr-4">
                  <input
                    type="text"
                    value={filterTitle}
                    onChange={(event) => setFilterTitle(event.target.value)}
                    placeholder="Filter by Title..."
                    className="mb-1 mt-[6px] h-[37px] w-full flex-grow rounded-[8px] border border-gray-200 bg-white/0 p-3 text-sm outline-none dark:!border-white/10 dark:text-white"
                  />
                  <TooltipHorizon
                    content={
                      <>
                        Click here to <b>clear</b> the <b>filter</b>
                      </>
                    }
                  >
                    <span
                      onClick={() => {
                        setFilterTitle('');
                      }}
                      className="ml-2" // Optional: Adds space between input and tooltip button
                    >
                      <IconButton
                        extra={`${
                          filterTitle.length > 0
                            ? 'bg-red-500 hover:bg-red-600'
                            : ''
                        }`}
                      >
                        <MdClear />
                      </IconButton>
                    </span>
                  </TooltipHorizon>
                </div>
              </div>
            </div>
          )}

          <div className="px-4">
            <UnstyledList
              mode="switcher"
              targetPath={targetPath}
              setOpened={setOpened}
              opened={opened}
            />
          </div>
        </Card>
      )}
      <div
        className={opened ? 'pointer-events-none opacity-75 blur-[1px]' : ''}
      >
        {children}
      </div>
    </>
  );
};

export default Switcher;
