import { MutableRefObject, ReactElement, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Editor as TinyMCEEditor } from 'tinymce';
import TResource from './type';
import { Image } from './type';
import { PagedCollection } from '../../interfaces/Collection';
import EmptyModalBox from '../base/modal/EmptyModalBox';
import useUpdate from 'hooks/useUpdate';
import TooltipHorizon from 'components/tooltip';
import { MediaObject } from 'apiplatform/interfaces/MediaObject';
import InsertImage from './InsertImage';
import ArticleHistory from './ArticleHistory';
import IconButton from 'components/fields/IconButton';
import { MdClose } from 'react-icons/md';
import { PageCard } from 'components/card/PageCard';
import Revision from './Revision';
import ArticleButtons from './ArticleButtons';
import Autosave from './Autosave';

interface ShowProps {
  article: TResource;
  wpRetrieved: PagedCollection<TResource> | null;
  wpLoading: boolean;
  onClose: () => void;
  customEditorRef?: MutableRefObject<TinyMCEEditor>;
  bottomArea?: ReactElement;
  liveStream?: boolean;
}

const Show = ({
  article,
  wpLoading,
  wpRetrieved,
  onClose,
  customEditorRef,
  bottomArea,
  liveStream,
}: ShowProps) => {
  const [content, setContent] = useState(article.content);
  const [title, setTitle] = useState(article.title);
  const { updated, update, loading } = useUpdate<TResource>();
  const insertImageRef = useRef<HTMLSpanElement>(null);
  const articleHistoryRef = useRef<HTMLSpanElement>(null);
  const editorRef = useRef<TinyMCEEditor>(null);
  const [images, setImages] = useState<Image[]>([]);
  const [editorChanged, setEditorChanged] = useState(false);

  const resetEditorChangedFlag = (changed: boolean) => {
    setEditorChanged(changed);
  };

  const handleEditorChange = (newContent: string) => {
    setContent(newContent);
    setEditorChanged(true); 
  };

  return (
    <PageCard>
      <p
        className={` ${
          liveStream ? '' : 'hidden'
        } py-[11px] text-[12px] font-semibold`}
      >
        Note: Only one article can be generated at a time. If the process is
        interrupted or closed, the article will be lost. Please be patient and
        wait until completed.
      </p>
      <h1 className="relative mb-[1.5rem] mt-1 flex items-center text-[22px] font-bold capitalize hover:text-navy-700 dark:hover:text-white">
        <div className="flex flex-grow items-center">
          <TooltipHorizon content="The Article Title" block={true}>
            <input
              type="text"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
                setEditorChanged(true);
              }}
              placeholder="The Article Title"
              className="is-valid h-[32px] w-full rounded-[8px] border border-gray-200 bg-white/0 p-2 text-sm outline-none dark:!border-white/10 dark:text-white sm:min-w-[400px] md:min-w-[550px] lg:min-w-[650px] xl:min-w-[750px]"
            />
          </TooltipHorizon>
        </div>

        <div className="ml-4 mt-2 flex items-center">
          {article['@id'] !== '' && (
            <Autosave
              article={article}
              content={content}
              title={title}
              loading={loading}
              updated={updated && !loading}
              update={update}
              editorChanged={editorChanged}
              setEditorChanged={resetEditorChangedFlag}
            />
          )}

          <span onClick={onClose}>
            <TooltipHorizon content="Close">
              <IconButton extra="bg-red-500 hover:bg-red-600">
                <MdClose />
              </IconButton>
            </TooltipHorizon>
          </span>
        </div>
      </h1>

      {article.type === 'audio' && article.mediaObject && (
        <audio controls src={(article.mediaObject as MediaObject).contentUrl} />
      )}
      {article.type === 'video' && article.mediaObject && (
        <video controls src={(article.mediaObject as MediaObject).contentUrl} />
      )}
      {article.type === 'article' && (
        <>
          <Editor
            onInit={(evt, editor) => {
              editorRef.current = editor;
              if (customEditorRef) {
                customEditorRef.current = editor;
              }
            }}
            onEditorChange={handleEditorChange}
            tinymceScriptSrc="https://cdnjs.cloudflare.com/ajax/libs/tinymce/7.1.2/tinymce.min.js"
            initialValue={article.content}
            init={{
              license_key: 'gpl',
              branding: false,
              menubar: false,
              height: '600px',
              plugins: 'linkchecker autolink link',
              setup: (editor) => {
                editor.ui.registry.addButton('insertimage', {
                  icon: 'image',
                  tooltip: 'Insert Image',
                  onAction: () => {
                    insertImageRef.current?.click();
                  },
                });
                editor.ui.registry.addButton('articlehistory', {
                  icon: 'revision-history',
                  tooltip: 'Revision History',
                  onAction: () => {
                    articleHistoryRef.current?.click();
                  },
                });
              },
              toolbar:
                'undo redo | formatselect | h1 h2 h3 h4 h5 h6 | ' +
                'bold italic underline header | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'link code | removeformat | insertimage',
              content_style: `body { font-size: 14px }`,
              skin: 'oxide',
            }}
          />

          {article['@id'] !== '' && (
            <Revision content={content} editorRef={editorRef} />
          )}

          <EmptyModalBox
            item={<span ref={insertImageRef} className="hidden"></span>}
          >
            <InsertImage
              editorRef={editorRef}
              article={article}
              images={images}
              setImages={setImages}
            />
          </EmptyModalBox>
          <EmptyModalBox
            item={<span ref={articleHistoryRef} className="hidden"></span>}
          >
            <ArticleHistory editorRef={editorRef} />
          </EmptyModalBox>
        </>
      )}
      {article['@id'] !== '' && (
        <ArticleButtons
          article={article}
          editorRef={editorRef}
          wpRetrieved={wpRetrieved}
          wpLoading={wpLoading}
          updated={updated && !loading}
          update={update}
          loading={loading}
          content={content}
          title={title}
          onClose={onClose}
        />
      )}
      {bottomArea ? bottomArea : null}
    </PageCard>
  );
};

export default Show;
