import { useState, useEffect, useRef } from 'react';
import { debounce } from 'lodash';
import TResource from '../apiplatform/components/businessarticle/type';

interface SaveData {
  content: string;
  title: string;
}

interface UseAutosaveProps {
  article: TResource;
  update: (article: TResource, data: SaveData) => void;
  content: string;
  title: string;
  loading: boolean;
  updated: boolean;
  editorChanged: boolean;
  setEditorChanged: (changed: boolean) => void;
}

const useAutosave = ({
  article,
  update,
  content,
  title,
  loading,
  updated,
  editorChanged,
  setEditorChanged,
}: UseAutosaveProps) => {
  const [autosaveStatus, setAutosaveStatus] = useState<
    'idle' | 'saving' | 'saved'
  >('idle');

  const debouncedSave = useRef(
    debounce((newContent: string, newTitle: string) => {
      update(article, { content: newContent, title: newTitle });
    }, 2000)
  ).current;

  useEffect(() => {

    if (loading){
      setAutosaveStatus('saving');

    }
    if (updated && !loading) {
      setAutosaveStatus('saved');
      setEditorChanged(false);
    }
  }, [updated, loading]);

  useEffect(() => {
    if (editorChanged) {
      const timer = setTimeout(() => {
        setAutosaveStatus('saving'); 
        debouncedSave(content, title); 
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [editorChanged, content, title]);

  useEffect(() => {
    if (autosaveStatus === 'saved') {
      const idleTimeout = setTimeout(() => setAutosaveStatus('idle'), 2000);
      return () => clearTimeout(idleTimeout);
    }
  }, [autosaveStatus]);

  useEffect(() => {
    return () => {
      debouncedSave.cancel();
    };
  }, [debouncedSave]);

  return {
    autosaveStatus,
  };
};

export default useAutosave;
