import CreateNew from './CreateNew';
import { scrollToTop } from 'components/scrollToTop';
import EmptyModalBox from '../base/modal/EmptyModalBox';
import Create from 'apiplatform/components/businesstranscript/Create';
import { Subpage } from './List';

interface CreateNewOptionsProps {
  setSubpage: (subpage: Subpage) => void;
}

const CreateNewOptions = ({ setSubpage }: CreateNewOptionsProps) => {
  return (
    <div className="flex flex-col gap-6 py-8 sm:flex-row">
      <CreateNew
        title="Create Articles from Business Details"
        description="Ideal for those just starting out and who have no media to upload yet for content creation."
        color="yellow-500"
        label="Good"
        onClick={() => {
          setSubpage({
            type: 'business-articles',
            title: 'About your Business',
          });
          scrollToTop();
        }}
      />
      <CreateNew description="" color="blue-500" label="Better" >
        <EmptyModalBox
          item={
            <div className="">
              <p className="mt-[1] text-[14px] font-medium text-gray-800 sm:whitespace-nowrap  sm:leading-normal md:text-wrap">
                Create Articles from custom Transcript
              </p>
              <p className="mt-1 whitespace-normal text-[12px] leading-tight text-[#636e91] sm:whitespace-nowrap sm:leading-normal md:text-wrap">
                Create additional articles from an existing piece of content you
                may already have on your website.
              </p>
            </div>
          }
        >
          <Create />
        </EmptyModalBox>
      </CreateNew>

      <CreateNew
        title="Create Content from Audio / Video File"
        description="Create Articles and Video highlights from a video. Or upload an audio file to create Articles."
        color="green-600"
        label="Best"
        onClick={() => {
          setSubpage({ type: 'video-transcript' });
          scrollToTop();
        }}
      />
    </div>
  );
};

export default CreateNewOptions;
