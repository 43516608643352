import React from 'react';
import { useLocation } from 'react-router-dom';
import useRetrieve from 'hooks/useRetrieve';
import { ContentTheme } from 'apiplatform/interfaces/ContentTheme';

const ThemeDisplay: React.FC<{ brandText: string }> = ({ brandText }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isAllThemes = searchParams.get('allThemes') === '1';

  const pathSegments = location.pathname.split('/');
  const ctidIndex = pathSegments.indexOf('theme') + 1;
  const ctid = pathSegments[ctidIndex];

  const { loading, retrieved } = useRetrieve<ContentTheme>(
    `/businesses/content_themes/${ctid}`
  );

  const getThemeName = (): string => {
    if (!ctid) return '';
    if (loading) return '  ';
    if (location.pathname.endsWith('/themes') || location.pathname.endsWith('/prompts')) return '';
    if (isAllThemes) return ' - Theme: All Themes';
    return ' - Theme: ' + retrieved.name;
  };

  return (
    <span>
      {brandText}
      {getThemeName()}
    </span>
  );
};

export default ThemeDisplay;
