import { FunctionComponent } from 'react';
import Button from '../../components/fields/Button';
import { SocialApps } from '../ModalJSX/SocialApps';
import EmptyModalBox from '../../apiplatform/components/base/modal/EmptyModalBox';
import { EditTweet } from '../ModalJSX/EditTweet';
import CenteredButtons from '../../apiplatform/components/base/CenteredButtons';

interface TwitterButtonsProps {
  tweet: string;
  articleUrl: string;
  onTweetSave: (cleanedContent: string) => void;
  onClose?: () => void;
}

const TwitterButtons: FunctionComponent<TwitterButtonsProps> = ({
  tweet,
  articleUrl,
  onTweetSave,
  onClose,
}) => {
  return (
    <CenteredButtons>
      <div className="inline-block  ">
        <SocialApps tweet={tweet} url={articleUrl} />
      </div>
      <div className="inline-flex flex-wrap ">
        <EmptyModalBox
          item={
            <Button
              type="blue"
              label="Edit"
              className="mt-2 md:mt-0"
              leftMargin="modal"
            />
          }
        >
          <EditTweet tweet={tweet} onSave={onTweetSave} />
        </EmptyModalBox>
        <Button
          type="blue"
          label="Close"
          leftMargin="modal"
          className="mt-2 md:mt-0"
          onClick={onClose}
        />
      </div>
    </CenteredButtons>
  );
};

export default TwitterButtons;
