import { useLocation, useParams } from 'react-router-dom';
import { PagedCollection } from '../../interfaces/Collection';
import Show from './Show';
import ErrorAlert from 'components/alert/ErrorAlert';
import { PageCard } from 'components/card/PageCard';
import DataTableView from '../../../components/datatable/DataTableView';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import Moment from 'react-moment';
import { BusinessWordpressConfig } from '../../interfaces/BusinessWordpressConfig';
import { ContentTheme } from 'apiplatform/interfaces/ContentTheme';
import useCollectionUrl from 'apiplatform/hooks/collectionurl';
import { Link } from 'react-router-dom';
import { ExplainerText } from '../base/ExplainerText';
import IconButton from 'components/fields/IconButton';
import { mdiCheckUnderline } from '@mdi/js';
import { MdAdd, MdArticle, MdAudioFile } from 'react-icons/md';
import { ImVideoCamera } from 'react-icons/im';
import TooltipHorizon from 'components/tooltip';
import { BusinessArticle } from 'apiplatform/interfaces/BusinessArticle';
import ThemeSwitcher from '../contenttheme/Switcher';
import useDelete from 'hooks/useDelete';
import useRetrieve from 'hooks/useRetrieve';
import Icon from '@mdi/react';
import { mdiCreation } from '@mdi/js';
import { mdiCalendar } from '@mdi/js';
import { mdiHelp } from '@mdi/js';
import { mdiAlphaXCircle } from '@mdi/js';
import { FaAddressCard } from 'react-icons/fa';
import { scrollToTop } from 'components/scrollToTop';
import { useAuthContext } from 'components/auth/AuthProvider';

const List = () => {
  const { error: deleteError, del } = useDelete<BusinessArticle>();

  const { id, ctid } = useParams();
  const auth = useAuthContext();
  const location = useLocation();

  const { url: articleId } = useCollectionUrl(
    `/businesses/${id}/articles?contentTheme=${ctid}`,
    {
      defaultSortBy: 'createdAt',
      defaultSortOrder: 'desc',
    }
  );

  const { retrieved, loading, error } =
    useRetrieve<PagedCollection<BusinessArticle>>(articleId);

  const wpRetrive = useRetrieve<BusinessWordpressConfig>(
    decodeURIComponent(`/businesses/${id}/wordpress-configs`)
  );

  const [selectedArticle, setSelectedArticle] = useState<BusinessArticle>(null);

  useEffect(() => {
    setSelectedArticle(null);
  }, [ctid]);

  useEffect(() => {
    setSelectedArticle(null);
  }, [location]);

  const hasAccess: boolean = useMemo<boolean>(
    () => auth.isBusinessOwner(parseInt(id)),
    [auth, id]
  );

  return (
    <>
      <ExplainerText text="The Queue displays all of your content across various content themes and topics in one centralized view." />
      <ThemeSwitcher hide={!hasAccess} targetPath="queue">
        {selectedArticle && (
          <div className="mb-5">
            <Show
              onClose={() => setSelectedArticle(null)}
              key={selectedArticle['@id']}
              article={selectedArticle}
              wpLoading={wpRetrive.loading}
              wpRetrieved={wpRetrive.retrieved}
            />
          </div>
        )}

        <PageCard>
          {error && <ErrorAlert message={error.message} />}
          {deleteError && <ErrorAlert message={deleteError.message} />}
          <DataTableView
            retrieved={retrieved}
            loading={loading}
            noDataComponent={
              <>
                You don't have any media yet.
                <br />
                Click at the button with a plus sign to create one.
              </>
            }
            columns={[
              {
                name: 'Title',
                sortable: true,
                id: 'title',
                width: '40%',
                cell: (row) => (
                  <span
                    className={`w-full cursor-pointer py-4 text-sm font-bold text-navy-700 dark:text-white 
                      ${
                        selectedArticle && selectedArticle['@id'] === row['@id']
                          ? '!text-blue-500'
                          : ''
                      } 
                      sm:w-auto sm:text-clip `}
                    onClick={() => {
                      setSelectedArticle(row);
                      scrollToTop();
                    }}
                  >
                    {row.title}
                  </span>
                ),
              },
              {
                name: 'Media Type',
                sortable: true,
                id: 'type',
                cell: (row) => {
                  let statusText = '';
                  let statusIcon: ReactNode;

                  switch (row.source) {
                    case (row.source = 'business-details'):
                      statusText = 'Article from Business Details';
                      statusIcon = <FaAddressCard />;
                      break;
                    case (row.source = 'transcript'):
                      statusText = 'Article from Transcript';
                      statusIcon = <MdArticle />;
                      break;
                    default:
                      statusText = 'Unknown Status';
                  }

                  if (row.type === 'audio') {
                    statusText = 'Audio File';
                    statusIcon = <MdAudioFile />;
                  }
                  if (row.type === 'video') {
                    statusText = 'Video File';
                    statusIcon = <ImVideoCamera />;
                  }

                  return (
                    <TooltipHorizon content={statusText}>
                      <div className="ml-2 px-2 py-2 text-[16px] font-medium text-blue-900">
                        {statusIcon}
                      </div>
                    </TooltipHorizon>
                  );
                },
              },
              {
                name: 'Theme',
                sortable: true,
                id: 'contentTheme.id',
                cell: (row) => {
                  return (
                    <div className="text-navy-700 dark:text-white">
                      {(row.contentTheme as ContentTheme).name}
                    </div>
                  );
                },
              },

              {
                name: 'Status',
                id: 'status',
                sortable: true,
                cell: (row) => {
                  let statusText = '';
                  let statusIcon: JSX.Element | null = null; // Define statusIcon as JSX.Element or null

                  switch (row.status) {
                    case 'new':
                      statusText = 'New';
                      statusIcon = <Icon path={mdiCreation} size="16px" />;

                      break;
                    case 'scheduled':
                      statusText = 'Scheduled';
                      statusIcon = <Icon path={mdiCalendar} size="16px" />;

                      break;
                    case 'sent':
                      statusText = 'Published';
                      statusIcon = (
                        <Icon path={mdiCheckUnderline} size="16px" />
                      );
                      break;
                    case 'error':
                      statusText = 'CMS Connection Failed';
                      statusIcon = <Icon path={mdiAlphaXCircle} size="20px" />;
                      break;
                    default:
                      statusText = 'Unknown Status';
                      statusIcon = <Icon path={mdiHelp} size="16px" />;
                  }
                  return (
                    <TooltipHorizon content={`${statusText}`}>
                      <div className="ml-2  rounded-lg px-2 py-2 text-[13px] font-medium text-blue-900  transition duration-200 ">
                        {statusIcon}
                      </div>
                    </TooltipHorizon>
                  );
                },
              },

              {
                name: 'Date',
                id: 'createdAt',
                sortable: true,
                cell: (row) => (
                  <Moment
                    format="MMMM Do YYYY"
                    className="text-navy-700 dark:text-white"
                  >
                    {row.createdAt}
                  </Moment>
                ),
              },
              {
                name: (
                  <div className="hidden w-full text-right sm:block">
                    {hasAccess && (
                      <TooltipHorizon content="Create New Content">
                        <Link to={`/business/${id}/theme/${ctid}/transcripts`}>
                          <IconButton>
                            <MdAdd />
                          </IconButton>
                        </Link>
                      </TooltipHorizon>
                    )}
                  </div>
                ),
                width: '62px',
              },
            ]}
          />
        </PageCard>
        {hasAccess && (
          <div className="fixed bottom-16 right-6 mt-3 md:hidden ">
            <TooltipHorizon content="">
              <Link to={`/business/${id}/theme/${ctid}/transcripts`}>
                <IconButton extra="rounded-3xl">
                  <MdAdd size={22} />
                </IconButton>
              </Link>
            </TooltipHorizon>
          </div>
        )}
      </ThemeSwitcher>
    </>
  );
};

export default List;
