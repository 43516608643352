import { PropsWithChildren } from 'react';

interface HeaderProps extends PropsWithChildren {
  text: string;
}

const Header = ({ text, children }: HeaderProps) => {
  return (
    <h1 className={`mb-8 md:text-3xl text-xl mr-8 md:mr-0 font-bold`}>
      {text}
      <span className="float-right">{children}</span>
    </h1>
  );
};

export default Header;
