// MobileOptions
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FaVideo, FaWordpressSimple } from 'react-icons/fa';
import { MdEdit, MdDelete, MdAdd, MdPlayArrow, MdError } from 'react-icons/md';
import { IoMenu } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from 'components/auth/AuthProvider';
import Button from '../../../components/fields/Button';
import EmptyModalBox from '../base/modal/EmptyModalBox';
import Show from './Show';
import Delete from './Delete';
import TooltipHorizon from '../../../components/tooltip';
import IconButton from '../../../components/fields/IconButton';
import Create from './Create';
import { BusinessTranscript } from '../../interfaces/BusinessTranscript';
import { Subpage } from './List';
import { scrollToTop } from '../../../components/scrollToTop';

interface MobileOptionsProps {
  row: BusinessTranscript;
  setSubpage: Dispatch<SetStateAction<Subpage>>;
  subpage: Subpage;
}

const MobileOptions: React.FC<MobileOptionsProps> = ({ row, setSubpage, subpage }) => {
  const navigate = useNavigate();
  const auth = useAuthContext();
  const [menuVisible, setMenuVisible] = useState(false);

  const handleToggleMenu = () => setMenuVisible(!menuVisible);
  const closeMenu = () => setMenuVisible(false);

  // function setSubpage(arg0: {
  //   type: string;
  //   transcriptId: string;
  //   title: any;
  // }) {
  //   throw new Error('Function not implemented.');
  // }

  return (
    <>
      <Button
        type="blue"
        label={<IoMenu className="text-base" />}
        customButton="rounded-lg px-[8px] py-[8px] w-full w-min text-sm sm:text-base truncate md:hidden"
        onClick={handleToggleMenu}
      />

      {menuVisible && (
        <div
          className={`border-3 fixed bottom-0 left-0 right-0 !z-10 mx-auto flex w-full flex-col justify-start rounded-t-[20px]  
            border bg-white pb-4 text-left shadow-2xl dark:bg-navy-700 dark:text-white md:hidden `}
        >
          <p className="my-2 pl-6 text-gray-500">{row.title}</p>

          {row.originalFileName && (
            <Button
              type="white"
              label={
                <div className="my-2 inline-flex pl-2">
                  <FaVideo className="ml-4 text-lg text-blue-900" />
                  <p className="pl-2">Generate Video Suggestions</p>
                </div>
              }
              customButton="mt-3 text-sm text-left text-gray-800 dark:text-white"
              onClick={() => {
                setSubpage({
                  type: 'video-suggestions',
                  transcriptId: row['@id'],
                  title: row.title,
                });
                closeMenu()
              }}
            />
          )}

          <div className="my-2 border-t border-gray-500"></div>

          <Button
            type="white"
            label={
              <div className="my-2 inline-flex pl-2">
                <MdPlayArrow className="ml-4 text-lg text-blue-900" />
                <p className="pl-2">Generate Article Suggestions</p>
              </div>
            }
            customButton="mt-3 text-sm text-left text-gray-800 dark:text-white"
            onClick={() => {
              setSubpage({
                type: 'transcript-articles',
                transcriptId: row['@id'],
                title: row.title,
              });
              scrollToTop();
              closeMenu()
            }}
          />

          <div className="my-2 border-t border-gray-500"></div>

          {row.businessTranscriptStatus === 'error' && (
            <>
              <EmptyModalBox
                item={
                  <Button
                    type="white"
                    label={
                      <div className="inline-flex pl-2">
                        <MdDelete className="ml-4 text-lg text-blue-900" />
                        <p className="pl-2">Delete</p>
                      </div>
                    }
                    customButton="mt-3 text-sm text-left text-gray-800 dark:text-white"
                  />
                }
              >
                <Delete item={row} />
              </EmptyModalBox>

              <div className="my-2 border-t border-gray-500"></div>

              <Button
                type="white"
                label={
                  <div className="inline-flex pl-2">
                    <MdError className="ml-4 text-xl text-red-500" />
                    <p className="pl-2">Error processing the media</p>
                  </div>
                }
                customButton="mt-3 text-sm text-left text-gray-800 dark:text-white"
              />

              <div className="my-2 border-t border-gray-500"></div>
            </>
          )}

          <EmptyModalBox
            width={800}
            item={
              <Button
                type="white"
                label={
                  <div className="inline-flex pl-2">
                    <MdEdit className="ml-4 text-lg text-blue-900" />
                    <p className="pl-2">Edit</p>
                  </div>
                }
                customButton="mt-3 text-sm text-left text-gray-800 dark:text-white"
              />
            }
          >
            <Show transcript={row} />
          </EmptyModalBox>

          <div className="my-2 border-t border-gray-500"></div>

          <EmptyModalBox
            item={
              <Button
                type="white"
                label={
                  <div className="inline-flex pl-2">
                    <MdDelete className="ml-4 text-lg text-blue-900" />
                    <p className="pl-2">Delete</p>
                  </div>
                }
                customButton="mt-3 text-sm text-left text-gray-800 dark:text-white"
              />
            }
          >
            <Delete item={row} />
          </EmptyModalBox>
        </div>
      )}
      <div className={`fixed bottom-16 right-6 mt-3 md:hidden`}>
        {auth.isOwner() && (
          <EmptyModalBox
            width={800}
            item={
              <TooltipHorizon content="">
                <IconButton extra="rounded-3xl">
                  <MdAdd size={22} />
                </IconButton>
              </TooltipHorizon>
            }
          >
            <Create />
          </EmptyModalBox>
        )}
      </div>
    </>
  );
};

export default MobileOptions;
