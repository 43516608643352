import useCollectionUrl from 'apiplatform/hooks/collectionurl';
import { PagedCollection } from 'apiplatform/interfaces/Collection';
import { Manager } from 'apiplatform/interfaces/Manager';
import useRetrieve from 'hooks/useRetrieve';
import { ExplainerText } from '../base/ExplainerText';
import { PageCard } from 'components/card/PageCard';
import DataTableView from 'components/datatable/DataTableView';
import IconButton from 'components/fields/IconButton';
import TooltipHorizon from 'components/tooltip';
import { MdAdd, MdEdit } from 'react-icons/md';
import EmptyModalBox from '../base/modal/EmptyModalBox';
import Create from './Create';
import Edit from './Edit';
import useAuth from 'components/auth/useAuth';

const List = () => {
  const { url } = useCollectionUrl('/users/managers');
  const { retrieved, loading } = useRetrieve<PagedCollection<Manager>>(url);
  const auth = useAuth();

  return (
    <>
      <ExplainerText text="Manage user accounts and assign access to either just the queue or all features within a selected business." />
      <PageCard>
        <DataTableView
          retrieved={retrieved}
          loading={loading}
          noDataComponent="No User Accounts yet. Click the plus (+) sign to create one"
          columns={[
            {
              name: 'Email',
              id: 'email',
              sortable: false,
              cell: (row) => (
                <span className="w-full py-4 text-sm font-bold text-navy-700 dark:text-white">
                  {row.email}
                </span>
              ),
              width: '200px',
            },
            {
              name: 'Businesses',
              id: 'businessPermissions',
              sortable: false,
              cell: (row) => (
                <span className="w-full py-4 text-sm font-bold text-navy-700 dark:text-white">
                  {row.businessPermissions
                    .map((bp) => bp.business.name)
                    .join(', ')}
                </span>
              ),
              width: '150px',
            },
            {
              name: (
                <div className="hidden w-full text-right sm:block">
                  <EmptyModalBox
                    item={
                      <TooltipHorizon content="Add Manager">
                        <IconButton>
                          <MdAdd />
                        </IconButton>
                      </TooltipHorizon>
                    }
                  >
                    <Create />
                  </EmptyModalBox>
                </div>
              ),
              id: 'modals',
              sortable: false,
              cell: (row) => (
                <div className="w-full text-right">
                  {auth.getId() !== row.id && (
                    <EmptyModalBox
                      item={
                        <TooltipHorizon content="Edit">
                          <IconButton>
                            <MdEdit />
                          </IconButton>
                        </TooltipHorizon>
                      }
                    >
                      <Edit manager={row} />
                    </EmptyModalBox>
                  )}
                </div>
              ),
            },
          ]}
        />
      </PageCard>

      <div className="fixed bottom-16 right-6 mt-3 md:hidden ">
        <TooltipHorizon content="">
          <EmptyModalBox
            item={
              <TooltipHorizon content="Add Manager">
                <IconButton extra="rounded-3xl">
                  <MdAdd size={22} />
                </IconButton>
              </TooltipHorizon>
            }
          >
            <Create />
          </EmptyModalBox>
        </TooltipHorizon>
      </div>
    </>
  );
};

export default List;
