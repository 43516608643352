import Button from "components/fields/Button";
import { MouseEventHandler } from 'react';

interface SubmitProps {
  label?: string
  loading?: boolean
  completed?: boolean
  disabled?: boolean
  className?: string
  onClick?: MouseEventHandler<HTMLButtonElement>;
  custom?:string


}

const Submit = ({ label, loading, completed, disabled, className, custom, onClick }: SubmitProps) => {
  return (
    <Button label={label} type="blue" loading={loading} disabled={loading || disabled} completed={completed} className={className} customButton={custom} onClick={onClick}/>
  );
};

export default Submit;
