export const generateFullDayTimeOptions = (): string[] => {
  const options = [];
  const start = new Date();
  start.setHours(0, 0, 0, 0);

  for (let i = 0; i < 96; i++) {
    const time = new Date(start.getTime() + i * 15 * 60 * 1000);
    options.push(formatTime(time.getHours(), time.getMinutes()));
  }

  return options;
};

export const formatTime = (hour: number, minute: number): string => {
  const period = hour >= 12 ? 'PM' : 'AM';
  const adjustedHour = hour % 12 || 12; 
  const paddedMinute = minute.toString().padStart(2, '0');
  return `${adjustedHour}:${paddedMinute} ${period}`;
};

export const parseTime = (timeString: string): [number, number] => {
  const [time, period] = timeString.split(' ');
  let [hour, minute] = time.split(':').map(Number);
  if (period === 'PM' && hour !== 12) hour += 12; 
  if (period === 'AM' && hour === 12) hour = 0; 
  return [hour, minute];
};

const convertTo24HourFormat = (hour: string, period: string): number => {
  let hourInt = parseInt(hour, 10);
  if (period === "PM" && hourInt !== 12) hourInt += 12; 
  if (period === "AM" && hourInt === 12) hourInt = 0; 
  return hourInt;
};

export const setEventTimeTo930AM = (eventDateTime: { date: string; hour: string; minute: string; period: string }): { date: string; hour: string; minute: string; period: string } => {
  const currentDate = new Date(); 
  const eventDate = new Date(eventDateTime.date); 

  if (currentDate.getTime() < eventDate.getTime() || (currentDate.getHours() < 9 || (currentDate.getHours() === 9 && currentDate.getMinutes() < 30))) {
    return {
      date: eventDateTime.date, 
      hour: '9', 
      minute: '30', 
      period: 'AM' 
    };
  }

  return eventDateTime;
};

export const findNearestTimeSlot = (
  options: string[],
  eventDateTime: { date: string; hour: string; minute: string; period: string }
): string => {
  const adjustedEventDateTime = setEventTimeTo930AM(eventDateTime);

  const { hour, minute, period } = adjustedEventDateTime;

  const eventHour24 = convertTo24HourFormat(hour, period);
  let eventMinutes = eventHour24 * 60 + parseInt(minute, 10);

  // Add a 5-minute buffer
  eventMinutes += 5;

  let closestTime = options[0];
  let closestDifference = Number.MAX_VALUE;

  options.forEach((option) => {
    const [formattedHour, formattedMinute] = parseTime(option);
    const optionMinutes = formattedHour * 60 + formattedMinute;

    // Ensure the option is at least 5 minutes ahead of the adjusted event time
    if (optionMinutes >= eventMinutes) {
      const difference = optionMinutes - eventMinutes;
      if (difference < closestDifference) {
        closestTime = option;
        closestDifference = difference;
      }
    }
  });

  return closestTime;
};

