import { PropsWithChildren } from 'react';

interface IconButtonProps extends PropsWithChildren {
  extra?: string;
  onHoverColor?: string;
  color?: string;
  disabled?: boolean;
  complete?: boolean;
}

// Function to merge and replace only rounded classes in className
const mergeClassNames = (baseClass: string, extraClass: string = '') => {
  // Define pattern for rounded class to replace
  const roundedPattern = /rounded-[a-z0-9]+/;

  // Replace matched rounded pattern in baseClass with extraClass if it exists
  let mergedClass = baseClass.replace(roundedPattern, (match) => {
    // Check if extraClass has a rounded pattern to replace
    const extraRounded = extraClass.match(/rounded-[a-z0-9]+/);
    return extraRounded ? extraRounded[0] : match;
  });

  // Add any extra classes that don’t match the rounded pattern
  return `${mergedClass} ${extraClass}`;
};

export const IconButton = ({
  children,
  extra = '',
  color,
  onHoverColor,
  disabled,
  complete,
}: IconButtonProps) => {
  const baseColor = complete ? 'bg-green-600' : color || 'bg-blue-900';
  const hoverColor = complete
    ? 'hover:bg-green-600'
    : onHoverColor || 'hover:bg-brand-600';

  const baseClass = `ml-2 inline-block cursor-pointer rounded-lg ${baseColor} ${hoverColor} 
                     px-2 py-2 text-[13px] font-medium text-white transition duration-200 
                     ${disabled ? 'pointer-events-none opacity-50' : ''}`;

  const finalClassName = mergeClassNames(baseClass, extra);

  return <span className={finalClassName}>{children}</span>;
};

export default IconButton;
